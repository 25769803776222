import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    'ottoInput',
    'latInput',
    'ottoText',
    'ottoTextCorrected',
    'ottoInputCorrected',
    'alternateTextField',
    'latinInputCorrected',
    'latinValueStatus', // TODO: needs to be removed
    'toBeCheckedCheckBox',
    'misspelledCheckBox',
    'form',
    'contextViewBtn',
    'startQuoteBtn',
    'endQuoteBtn',
    'addAlternateBtn',
    'formSubmitButton', // used in typing forms(edit partials) for ajax call to avoid sidebar items reload on item click
    'returnOrApproveModal', // TODO: this target is common for cropper controller & typing_form controller, find & use some js module approach for common attributes/functionalities
    'currentItemIndexContainer',
    'simpleStarIcon',
    'goldStarIcon',
    'bookmarkToggle',
    'markedAsVulgar',
    'markedAsOriginal',
    'markedAsVulgarCorrected',
    'markedAsOriginalCorrected'
  ];

  initialize() {
    window['typing_form'] = this
    this.bookmarkToggleTarget.classList.remove('collapse');
    // to keep on toggle of bookmark
    if (isEnabledDisplayOnlyBookmarkedItems) {
      this.bookmarkToggleTarget.checked = true
    }
    this.typing_stage = this.element.dataset['typing']
    this.typing_for = this.element.dataset['objectType']
    this.current_item_id = this.element.dataset['typingFormItemId'] // id of currently active item
    // Changing tabs quickly with keys had a weird issue
    this.pausedChangingPreviewItem = false
    this.currentItemNotes = parseInt(this.element.dataset['currentItemNotesCount'])
    // this.isValueLatinPresent = JSON.parse(this.element.dataset['valueLatinPresent?']);
    this.validOttomanCharacters = JSON.parse(this.element.dataset['validOttomanCharacters']) // Space is optionally allowed in dictionary setting
    this.validOttomanCharactersForAlternates = JSON.parse(this.element.dataset['validOttomanCharactersForAlternates'] || '{}');
    this.validLatinCharacters = [' '].concat(JSON.parse(this.element.dataset['validLatinCharacters'])) // Adding <space> as an allowed character    
    this.otherWordIds = JSON.parse(this.element.dataset['otherWordIds'])
    this.otherWordsColors = JSON.parse(this.element.dataset['otherWordColors'])
    this.otherWordsColors.forEach((color, index) => {
      let fillStyle = BuildlqColors.hexToRGB(color)
      this.otherWordsColors[index] = fillStyle; //populating other word colors
    })

    // to show duplicate alternate fields(with comma separated values)if root leter toggle value is on initially when page loads
    this.showDuplicateFieldsForRootLeteredAlternates();

    // adding event listners to both  actual and duplicate alternate fields 
    $(document).on('focus', '.js-duplicate-field', this.showOriginalAndHideDuplicateAlternateIfRootLtr.bind(this));
    $(document).on('blur', '.js-otto-field-for-alternates', this.showDuplicateAndHideOriginalAlternateIfRootLtr.bind(this));

    this.setCursorFocus()
    setTimeout(() => {
      this.setCursorFocus()
    }, 50);
    setTimeout(() => {
      this.setCursorFocus()
    }, 100);
    this.isNotesModalOpen = false;

    // TODO: Move to connect
    let modalElement = document.getElementById("js-preview-modal");
    modalElement.addEventListener("hide.bs.modal", () => {
      preview_modal = undefined
    })


    // // TODO: move to latin history controller when controller created 
    // document.body.addEventListener('shown.bs.modal', function(event)  {
    //   if(event.target.classList.contains('js-latin-history-modal')){
    //     isLatinHistoryModalOpen = true;
    //   }
    // });

    // // TODO: move to latin history controller when controller created
    // document.body.addEventListener('hide.bs.modal', function(event)  {
    //   if(event.target.classList.contains('js-latin-history-modal')){
    //     isLatinHistoryModalOpen = false;
    //   }
    // });

   //hiding add alternative buttons of simple ottoman value if item is misspelled.
    if(this.hasMisspelledCheckBoxTarget && this.misspelledCheckBoxTarget.checked) {
      $('.js-add-alternative-btn.js-simple-add-alt-btn').addClass('d-none');
    }

    // removing this class so alt + enter hotkeys won't focus on hidden corrected ottoman value fields
    if(this.hasMisspelledCheckBoxTarget && !this.misspelledCheckBoxTarget.checked) {
      $('.js-corrected-ottoman-field').removeClass('js-text-field');
    }

    this.initializeDragAndDropInAlternativeEntries(".js-simple-sortable-values", ".js-simple-values-sortable-container");
    this.initializeDragAndDropInAlternativeEntries(".js-corrected-sortable-values", ".js-corrected-values-sortable-container");
    // Global method
    scrollToCurrentSelectedItem({ behavior: 'instant' });
    // this.handleMarkedAsPrimaryRadioBtnVisibility()
  }

  // This method makes given divs moveable
  initializeDragAndDropInAlternativeEntries(moveable_class, containment_class) {
    const _this = this;

    $(moveable_class).each(function() {
      $(this).sortable({
        items: '.js-sortable-item',
        axis: 'y',
        handle: ".js-drag-handle",
        containment: $(this).closest(containment_class),
        tolerance: "pointer",
        cancel: '.js-not-sortable',              
        update: function(event, ui){
          _this.updateAddAltBtnsAndAssignedNumberFields(event, ui);
          typeo_form.redrawFieldConnections();
        }
      });
    });
  }

  // This methods add red/blue divider between other values text box containers and updates red/blue dividers hidden field value
  addOrRemoveDividerBetweenOtherValues(e) {
    const otherValueContainer    = $(e.target).closest('.js-other-value-container')[0] || $(e.target).closest('.js-typing-value-container')[0];
    const dividerContainer       = otherValueContainer.querySelector(".js-dividers-container");
    const clickedDividerIcon     = e.target.closest("svg");
    const dividersIconsContainer = $(e.target).closest('.js-dividers-icons-container')[0];
    const redDividerIcon         = dividersIconsContainer.querySelector('.js-red-divider');
    const blueDividerIcon        = dividersIconsContainer.querySelector('.js-blue-divider');
    
    if (dividerContainer.classList.contains('danger-border-bottom') || dividerContainer.classList.contains('blue-border-bottom')) {
      this.removeDivider(otherValueContainer, dividerContainer, redDividerIcon, blueDividerIcon);
    } else {
      this.addDivider(otherValueContainer, dividerContainer, blueDividerIcon, redDividerIcon, clickedDividerIcon);
    }
  }

  removeDivider(otherValueContainer, dividerContainer, redDividerIcon, closeDividerIcon){
    const redDividerHiddenField  = otherValueContainer.querySelector('.js-has-red-divider');
    const blueDividerHiddenField = otherValueContainer.querySelector('.js-has-divider');
    
    // Remove divider  
    dividerContainer.classList.remove('danger-border-bottom', 'blue-border-bottom', 'mt-1', 'mb-2');
    
    // Revert close icon color
    closeDividerIcon.classList.remove('text-danger');
    closeDividerIcon.classList.add('light-blue-color');
    closeDividerIcon.classList.remove('cross-divider-icon');
    closeDividerIcon.classList.add('blue-divider-icon');
    
    // Revert hidden field values 
    redDividerHiddenField.value = 'false';
    blueDividerHiddenField.value = 'false';

    // Display both divider icons
    redDividerIcon.classList.remove('collapse');

    // Change the icon from x-circle to plus-circle
    closeDividerIcon.setAttribute('data-feather', 'plus-circle');
    feather.replace();
  }

  addDivider(otherValueContainer, dividerContainer, blueDividerIcon, redDividerIcon, clickedDividerIcon){
    const isRedDividerClicked = clickedDividerIcon.classList.contains('js-red-divider');
    
    let dividerField;
    let dividerColorClass;
    let crossIconColorClass;

    if (isRedDividerClicked) {
      dividerColorClass   = 'danger-border-bottom';
      dividerField        = otherValueContainer.querySelector('.js-has-red-divider');
      crossIconColorClass = 'text-danger';
    } else {
      dividerColorClass   = 'blue-border-bottom';
      dividerField        = otherValueContainer.querySelector('.js-has-divider');
      crossIconColorClass = 'light-blue-color';
    }

    // Add divider in required color  
    dividerContainer.classList.add(dividerColorClass, 'mt-1', 'mb-2');
    
    // Update cross icon style
    blueDividerIcon.classList.add(crossIconColorClass);
    blueDividerIcon.classList.remove('blue-divider-icon');
    blueDividerIcon.classList.add('cross-divider-icon');
    
    // Update hidden field value 
    dividerField.value = 'true';

    // Hide red divider icon
    redDividerIcon.classList.add('collapse');

    // Change the icon from plus-circle to x-circle
    blueDividerIcon.setAttribute('data-feather', 'x-circle');
    feather.replace();
  }

  // Helper method to update the identifiers and assigned numbers of alternative fields
  // Summary:
  // This method first determines the position of the field immediately above the moved field after it has been repositioned.
  // It then increments the assigned values and identifiers by 1 for all fields located below this above field.
  // Subsequently, it updates the assigned value and identifier of the moved field.
  // If the moved field is placed at the top, its new assigned value and identifier will be set to 1.
  updateAddAltBtnsAndAssignedNumberFields(e, ui) {
    const movedAltField               = ui.item;
    // Find the field with class 'nested-form-wrapper' above the moved field
    const aboveMovedTextField         = movedAltField.prevAll('.nested-form-wrapper').first();
    const aboveMovedTextFieldPosition = aboveMovedTextField.find('button[data-add-alternative-btn-identifier]').attr('data-add-alternative-btn-identifier');
    
    // update assigned value and position identifier of fields that are located below aboveMovedTextField
    this.updateAddAltBtnsAndAssignedValueFieldsAfterSelectedField(aboveMovedTextFieldPosition);
    
    // update assigned value and position identifier of moved field
    let movedAltFieldUpdatedPosition     = parseInt(aboveMovedTextFieldPosition) + 1;
    const movedAltFieldPrevPosition      = movedAltField.find('button[data-add-alternative-btn-identifier]').attr('data-add-alternative-btn-identifier');
    const movedAltFieldAddAlternativeBtn = movedAltField.find('button[data-add-alternative-btn-identifier]')[0];

   // when field is moved to the top then movedAltFieldUpdatedPosition will be null
    if (isNaN(movedAltFieldUpdatedPosition)) {
      movedAltFieldUpdatedPosition = 0;
    }

    this.setAddAltBtnIdentifier(movedAltFieldAddAlternativeBtn, movedAltFieldUpdatedPosition);
    this.updateAltEntryAssignedNumber(movedAltFieldPrevPosition, movedAltFieldUpdatedPosition);      
  }

  handleOriginalOrVulgarToggle(e){
    let parentElement = e.currentTarget.closest('.js-root-parent-container')
    if (e.currentTarget.dataset.typingFormTarget == 'markedAsVulgar') {
      parentElement.querySelector('.js-original-toggle').checked = false
    } else {
      parentElement.querySelector('.js-vulgar-toggle').checked = false
    } 
  }

  // it is called when page is reloaded to set initial values(when no neither focus/blur event occured initially) of alternate text fields actual alternate fields/ duplicate alternate fields based on R.Ltr. Toggle saved value
  showDuplicateFieldsForRootLeteredAlternates() {
    const alternateInputFieldContainers = document.querySelectorAll('.entries-input');
    alternateInputFieldContainers.forEach(container => {
      const rootLetterToggle = container.querySelector('.js-root-letter-toggle');
      const relatedTextField = container.querySelector('.js-otto-field-for-alternates');
    
      // disabled (using 'false &&') to prevent execution as this functionality is not required currently.
      if (false && rootLetterToggle && relatedTextField) {
        if (rootLetterToggle.checked) {
          const event = { target: relatedTextField };
          this.showDuplicateAndHideOriginalAlternateIfRootLtr(event);
        } else {
          const relatedDuplicateField = container.querySelector('.js-duplicate-field');
          if (relatedDuplicateField) {
            const event = { target: relatedDuplicateField };
            this.showOriginalAndHideDuplicateAlternateIfRootLtr(event);
          }
        }
      }
    });
  }

  // this method adds comma between characters of passed text field value making sure that there are no more than one comma btw chars in case text already have comma separation
  getTextWithCommaBetweenCharacters(textFieldValue) {
    let textWithCommas = '';
    for (let i = 0; i < textFieldValue.length; i++) {
      textWithCommas += textFieldValue[i];
      if (textFieldValue[i] !== '،' && textFieldValue[i+1] !== '،' && i < textFieldValue.length - 1) {
        textWithCommas += '،';
      }
    }
    return textWithCommas;
  }

  // this method is called when cursor goes out of an duplicate alternate text field, this method checks if R.Ltr. toggle is on for an alternate text field, if it is on then it hides the duplicate field with comma separated value as cursor is not focused here and displays the actual alternate input field
  showOriginalAndHideDuplicateAlternateIfRootLtr(event) {
    const duplicateField = event.target;
    const container = duplicateField.closest('.entries-input');
    if (container) {
      const textField = container.querySelector('.js-otto-field-for-alternates');
      if (textField) {
        duplicateField.style.display = 'none';
        textField.style.display = 'block';
        textField.focus();
      }
    }
  }

  // this method is called when cursor goes in alternate text field, this method checks if R.Ltr. toggle is on for an alternate text field, if it is on then it displays a duplicate alternate field with comma separated values
  showDuplicateAndHideOriginalAlternateIfRootLtr(event) {
    const textField = event.target;
    const container = textField.closest('.entries-input'); // Find the closest specific container
    if (container) {
      const rootLetterToggle = container.querySelector('.js-root-letter-toggle');
      // disabled (using 'false &&') to prevent execution as this functionality is not required currently.
      if (false && rootLetterToggle && rootLetterToggle.checked) {
        const textWithCommas = this.getTextWithCommaBetweenCharacters(textField.value);
        const duplicateField = container.querySelector('.js-duplicate-field');
        if (duplicateField) {
          duplicateField.textContent = textWithCommas;
          // hiding the original alternate text input field and displaying duplicate field with comma separated values
          textField.style.display = 'none';
          duplicateField.style.display = 'block';
        }
      }
    }
  }

  handleRootLetterToggle(e){
    let relatedRootToggle = e.currentTarget.closest('.js-root-parent-container').querySelector('.marked-to-root-container')
    let relatedTextField  = e.currentTarget.closest('.entries-input').querySelector('.js-otto-field-for-alternates');
    if(e.currentTarget.checked) {
      // if Root Ltr. toggle is turned on then Root toggle will also be turned on and it(the simple Root) will be read only untill Root Ltr. is on
      relatedRootToggle.checked = true
      // this method is called here due to the requirement that harakat should be removed if root toggle is on and here Root Letter toggle is also making Root toggle on
      this.removeHarakatCharsFromTextFieldForRoot(e);
      // for read only when root toggle on
      relatedRootToggle.onclick = () => false;
      // if Root Ltr. toggle is on, then we will show the comma separated values
      if (relatedTextField) {
        const event = { target: relatedTextField }; // making this event because method showDuplicateAndHideOriginalAlternateIfRootLtr(e) takes events on text input fields
        this.showDuplicateAndHideOriginalAlternateIfRootLtr(event);
      }
    } else {
      // if Root Ltr. toggle is turned off then Root toggle will also be turned off and read only from Root will be removed
      relatedRootToggle.checked = false
      relatedRootToggle.onclick = () => true;
      // getting duplicate field (duplicate with comma separated values) to pass it as event to method showOriginalAndHideDuplicateAlternateIfRootLtr(e)
      let relatedDuplicateField = e.currentTarget.closest('.entries-input').querySelector('.js-duplicate-field');
      if (relatedDuplicateField) {
          const event = { target: relatedDuplicateField };
          this.showOriginalAndHideDuplicateAlternateIfRootLtr(event);
      }
      this.validOttomanCharactersForAlternates = JSON.parse(this.element.dataset['validOttomanCharactersForAlternates'])
    }
  }

  toggleBookmark(e){
    let url = null
    let url_type = null
    let data = {
      item_id: this.current_item_id,
      item_type: this.typing_for,
      typing: this.typing_stage
    };

    if (e.currentTarget.classList.contains('gold-star')) {
      this.goldStarIconTarget.classList.add('collapse')
      this.simpleStarIconTarget.classList.remove('collapse')
      document.querySelector('.js-selectable-entry.active').querySelector('.js-typing-list-item').dataset.starType = false
      
      url = '/page_set/bookmarks/' + this.current_item_id
      url_type = 'DELETE'
    } else {
      this.goldStarIconTarget.classList.remove('collapse')
      this.simpleStarIconTarget.classList.add('collapse')
      document.querySelector('.js-selectable-entry.active').querySelector('.js-typing-list-item').dataset.starType = true
      
      url = '/page_set/bookmarks'
      url_type = 'POST'
    }
    
    $.ajax({
      url: url ,
      type: url_type,
      data: data
    });
  }

  toggleBookmarkedItems(e){
    let allEntryItems = document.querySelectorAll('.js-selectable-entry')
    if (this.bookmarkToggleTarget.checked) {
      isEnabledDisplayOnlyBookmarkedItems = true
      allEntryItems.forEach((entry) => {
        if(entry.querySelector('.js-typing-list-item').dataset.starType == 'false'){
          // Temporarily doing this due to difference in staging vs local css difference
          entry.classList.remove('d-flex')
          entry.classList.add('hide_thumbnail')
        }
      })
    } else {
      isEnabledDisplayOnlyBookmarkedItems = false
      allEntryItems.forEach((entry) => {
        if(entry.classList.contains('hide_thumbnail')){
          entry.classList.add('d-flex')
          entry.classList.remove('hide_thumbnail')
        }
      })
    }
  }

  handleAlternateSpellingFieldsVisibility(alternateFieldsContainers, displayAlternateFields){
    alternateFieldsContainers.forEach(container => {
      if (displayAlternateFields) {
        container.classList.remove('d-none')
      } else {
        container.classList.add('d-none')
      }
    });
  }

  toggleAllPartToggles(partCheckBoxes, toBeChecked){
    partCheckBoxes.forEach(partCheckBox => {
      partCheckBox.checked = toBeChecked;
    });
  }

  handlePartOfOttomanToggle(e) {
    const isChecked                            = e.currentTarget.checked
    // const misspelledFieldsContainer         = document.querySelector('#js-misspelled-fields-container')
    // const btnAddAlternate                   = document.querySelector('.js-alternative-fields')
    const spellingAlternateFieldsContainers    = document.querySelectorAll('.js-alternative-fields');
    const misspelledOptionsCheckboxes          = document.querySelector('.js-misspelled-checkboxes-container')
    const togglesContainerToHideIfMarkedAsPart = e.currentTarget.closest('.js-entries-input').querySelectorAll('.js-toogles-to-hide-if-marked-as-part')
    const partCheckBoxes                       = document.querySelectorAll('.marked-as-part-checkbox');

    if (isChecked) {
      // misspelledFieldsContainer.classList.add('hide_thumbnail')
      // btnAddAlternate.classList.add('hide_thumbnail')
  
      // updating states of Part checkboxes for both the Primary and Corrected fields
      this.toggleAllPartToggles(partCheckBoxes, true);
      this.handleAlternateSpellingFieldsVisibility(spellingAlternateFieldsContainers, false)
      // this.misspelledCheckBoxTarget.checked = false
      this.misspelledCheckBoxTarget.setAttribute('disabled', true);
      misspelledOptionsCheckboxes.classList.add('hide_thumbnail');
      // togglesContainerToHideIfMarkedAsPart.classList.add('d-none');
      togglesContainerToHideIfMarkedAsPart.forEach(container => {
        container.classList.add('d-none'); // Add the 'd-none' class to hide the container
      });      
    } else {
      // misspelledFieldsContainer.classList.remove('hide_thumbnail')
      // btnAddAlternate.classList.remove('hide_thumbnail')

      // updating states of Part checkboxes for both the Primary and Corrected fields
      this.toggleAllPartToggles(partCheckBoxes, false);
      this.handleAlternateSpellingFieldsVisibility(spellingAlternateFieldsContainers, true)     
      this.misspelledCheckBoxTarget.disabled = false;
      misspelledOptionsCheckboxes.classList.remove('hide_thumbnail');
      // togglesContainerToHideIfMarkedAsPart.classList.remove('d-none');
      togglesContainerToHideIfMarkedAsPart.forEach(container => {
        container.classList.remove('d-none'); // Add the 'd-none' class to hide the container
      });      
    }
  }

  addSpecialCharacterForTypingForm(e){
    e.preventDefault();
    // TODO: use data-attribute to fetch value, instead of text within button
    if (e.currentTarget.innerText == 'ZWS') {
      this.addCharacterWhereCursorPointing('\u200C')
    } else {
      const character = e.currentTarget.getAttribute('data-mapped-value') || e.currentTarget.innerText;
      this.addCharacterWhereCursorPointing(character)
    }
  }

  getCursorPosition(currentCharacter, currentValue) {
    const cursorArray  = Array.from(currentValue); // Split by grapheme clusters
    const arrayLength  = cursorArray.length
    const previousChar = cursorArray[arrayLength - 1];

    const isPreviousCharacterHarakat = /[\u064B-\u0653]/.test(previousChar);

    let selectionStart = this.lastFocusedField.selectionStart;
    let selectionEnd   = this.lastFocusedField.selectionEnd;

    let cursorPosition = this.lastFocusedField.selectionStart;

    // if previous character is a Harkat and cursor is at the end of the word/spelling, then add 1 to current position of cursor to consider/add Harakat in the count of word letters
    if(isPreviousCharacterHarakat && selectionStart == selectionEnd){
      cursorPosition = cursorPosition + 1;
      this.lastFocusedField.selectionEnd = cursorPosition;
    }

    return cursorPosition;
  }

  addCharacterWhereCursorPointing(specialCharacter){
    const currentValue    = this.lastFocusedField.value;
    let cursorPosition    = this.getCursorPosition(specialCharacter, currentValue)
    let selectionEnd      = this.lastFocusedField.selectionEnd;
    let selectedCharacter = this.lastFocusedField.value.substring(this.lastFocusedField.selectionStart, this.lastFocusedField.selectionEnd);
    
    // To replace selected character with specialCharacter
    const newValue = currentValue.slice(0, cursorPosition) + specialCharacter + currentValue.slice(selectionEnd);
    this.lastFocusedField.value = newValue;
    this.lastFocusedField.setSelectionRange(cursorPosition + 1, cursorPosition + 1); // Move cursor after apostrophe
    this.lastFocusedField.focus();
    
    let event = new Event('input');
    this.lastFocusedField.dispatchEvent(event) // To call onchange callbacks
  }

  showItemOfWrittenIndex(e) {
    this.currentItemIndexContainerTarget.contentEditable = true;
    this.currentItemIndexContainerTarget.focus();
    this.currentItemIndexContainerTarget.innerText = '';
  }

  editToWrittenEntryItem(){
    let currentItemIndexContainer = this.currentItemIndexContainerTarget
    let allEntryItems = document.querySelectorAll('.js-selectable-entry')
    if (Number(currentItemIndexContainer.innerText) <= allEntryItems.length ) {
      allEntryItems[currentItemIndexContainer.innerText-1].click()
      currentItemIndexContainer.blur()
    } else {
      allEntryItems[allEntryItems.length - 1].click()
      currentItemIndexContainer.blur()
    }
    currentItemIndexContainer.contentEditable = false
  }

  setCursorFocus(){
    let entriesInputField;
    if(typing_form.isTypingLatinStage()) {
      entriesInputField = typing_form.latInputTarget;
      typing_form.lastFocusedField = typing_form.latInputTarget;
    } else {
      entriesInputField = typing_form.ottoInputTarget;
      typing_form.lastFocusedField = typing_form.ottoInputTarget;
    }

    // First new Alternate transcription button can be used to scroll, for a better UX
    entriesInputField.focus({ preventScroll: true });
    scrollIntoView(entriesInputField, {
      block: 'end', // To scroll only to show current typing element at end
      behavior: 'instant'
    })
  }

  // Manually, addition of alternative entry works independent of this method.
  // This method clicks that button to add alternative entry
  addAlternativeEntryManually(){
    let selectedText = window.getSelection().toString();
    this.lastFocusedField.closest('.js-typing-value-container').querySelector('.js-btn-add-alternate').click()
    // This assumes that lastFocusedField is the new alternateField, which is achieved by this method: focusToAlternateTranscription
    this.lastFocusedField.value = selectedText;

    this.filterValidCharacters({ currentTarget: this.lastFocusedField });
  }

  moveToNextTextField(){
    var textFields = document.querySelectorAll('.js-text-field');
    var nextIndex  = this.getNextFieldIndex(textFields);

    textFields[nextIndex].focus();
  }

  // copy and paste selected text to next transcription field
  copySelectedTextToNextTranscriptionField(selectedText){
    var textFields   = document.querySelectorAll('.js-latin-text-field');
    var nextIndex    = this.getNextFieldIndex(textFields);
    var nextElement  = textFields[nextIndex];

    // handled cases of empty selected value and last field 
    if (nextIndex == 0 || selectedText.length == 0) return;
    nextElement.value = selectedText;
    nextElement.focus();

    this.filterValidCharacters({ currentTarget: nextElement });
  }

  // Helper function to get the index of the next transcription field
  getNextFieldIndex(textFields) {
    var focusedElement = document.activeElement;
    var currentIndex   = Array.prototype.indexOf.call(textFields, focusedElement);
    
    return (currentIndex + 1) % textFields.length;
  }
  
  // Alternate entry is hid from view through nested-form library. However, need to set its _destroy flag (hidden field) to true for backend. Setting here
  removeAlternativeEntry(e) {
    let destroyFieldId = e.currentTarget.dataset.destroyFieldId;
    document.getElementById(destroyFieldId).value = true
  }

  // Alternate entry is hid from view through nested-form library. However, need to reset its component flag. Setting here
  uncheckComponentToggleFromDeletedField(e) {
    let destroyBtn = e.currentTarget;
    destroyBtn.closest('.js-entries-input').querySelector('input.js-marked-as-component-toggle').checked = false
  }

  setCurrentItemNotes(notes_count){
    this.currentItemNotes = notes_count
  }

  // This method sets the first corrected spelling as the primary corrected spelling when the misspelled toggle is turned on.
  clickMarkedAsPrimaryRadioBtnOfCorrectedOttoField() {
    const correctedSpellingContainer = $(".js-corrected-spellings-container");
    const correctedSpellingRadioBtn  = correctedSpellingContainer[0].querySelector('.js-marked-as-primary-radio-btn');
    
    this.resetEntryWordSpellingsExistingLinkages(correctedSpellingContainer[0]);
    this.handleSpellingLinkagesIcon(correctedSpellingContainer[0]);
    
    if (!correctedSpellingRadioBtn) return;
    correctedSpellingRadioBtn.click();
  }

  clickMarkedAsPrimaryRadioBtnOfPrimaryOttoField() {
    const primarySpellingContainer = $(".js-primary-spellings-container");
    const primarySpellingRadioBtn  = primarySpellingContainer[0].querySelector('.js-marked-as-primary-radio-btn');
    
    this.resetEntryWordSpellingsExistingLinkages(primarySpellingContainer[0]);
    this.handleSpellingLinkagesIcon(primarySpellingContainer[0]);
    
    if (!primarySpellingRadioBtn) return;
    primarySpellingRadioBtn.click();
  }

  // In this method we are deleting all the corrected alternates from front end if misspelled toggle is turned Off
  removeCorrectedAlternatesIfPresent() {
    const alternateFieldContainers = document.querySelectorAll('.js-other-value-container');
    alternateFieldContainers.forEach(container => {
      const removeButton = container.querySelector('.js-remove-alternative-entry');
      
      if (removeButton) {
        removeButton.click();
      }
    });
  }

  convertPrimaryAlternatesToCorrectedAlternates() {
    // here we are picking the div container for corrected alternate values
    const correctedAlternatesContainer = document.querySelector('.js-corrected-other-values-container');
    if (correctedAlternatesContainer) {
      // here we are picking all the alternate fields (currently primary's alternates)
      const alternateFieldContainers = document.querySelectorAll('.js-other-value-container');
      
      // here we are re-positioning all the primary's alternate fields from their primary container to corrected alternates container 
      alternateFieldContainers.forEach(alternateValueContainer => {
        correctedAlternatesContainer.appendChild(alternateValueContainer); // Move the element inside the target container
      });
    }

    // here we are updating the classes and the hidden field values of alternate fields to convert them from primary to corrected alternate
    const alternateFields = document.querySelectorAll('.js-alternate-ottoman-spelling-text-field');
    alternateFields.forEach(field => {
      field.classList.remove('js-simple-ottoman-alternative-value');
      field.classList.remove('for-corrected-value-false'); 

      field.classList.add('for-corrected-value-true');
      field.classList.add('js-corrected-ottoman-field');
      field.classList.add('js-corrected-ottoman-alternative-value');
      
      const alternateFieldcontainer = field.closest('.js-other-value-container');
      if (alternateFieldcontainer) {
        let hiddenFieldForIsCorrected = alternateFieldcontainer.querySelector('.js-is-corrected-ottoman-for-alternate-hidden-field');
        hiddenFieldForIsCorrected.value = true;
        // $(hiddenFieldForIsCorrected).trigger('change');
      }
    });
  }

  // When a word is marked as misspelled, certain toggles in primary fields are hidden. If the word is later unmarked as misspelled, the previously hidden toggles should be restored. This method handles these cases.
  handleVisibilityOfOttoSpellingToggles(isMisspelled){
    let primaryOttoFieldTogglesContainers = $('.js-primary-otto-field-toggles-container');
    let partToggleContainer               = $('.js-part-toggle-container-for-primary-spelling');

    if (isMisspelled){
      primaryOttoFieldTogglesContainers.each(function () {
        $(this).addClass('d-none');
      });
      partToggleContainer[0].classList.add('d-none');
    } else {
      primaryOttoFieldTogglesContainers.each(function () {
        $(this).removeClass('d-none');
      });
      partToggleContainer[0].classList.remove('d-none');
    }
  }

  // When the misspelled toggle is toggled on, we hide the radio buttons for all primary fields, so that only one of the corrected fields can be marked as primary.
  handleVisibilityOfMarkedAsPrimaryRadioBtnFromPrimaryFields(isMisspelled) {
    $(".js-marked-as-primary-radio-btn-container").each(function () {
      const isCorrectedOttomanField = this.closest('.js-entries-input')?.querySelector('.js-is-corrected-ottoman-hidden-field');
      const isCorrectedOttoman = isCorrectedOttomanField?.value;
      if (isMisspelled && isCorrectedOttoman === "false") {
        $(this).addClass("d-none");
      } else {
        $(this).removeClass("d-none");
      }
    });
  }

  uncheckTheCheckedComponentToggles(){
    const checkedComponentToggles = $('input.js-marked-as-component-toggle:checked');
    checkedComponentToggles.each(function() {
      $(this).click(); // to triggering any events bound to it
    }); 
  }

  // On selecting whether an item is misspelled, additional checkboxes are displayed and notes form is made visible.
  triggerMisspelled(e) {
    if (this.toBeCheckedCheckBoxTarget.checked && this.currentItemNotes > 0) {
      let msg = 'Please delete notes with conflicting labels before changing the label.'
      alert(msg)
      e.currentTarget.checked = false
      return false
    }

    // e.currentTarget.checked is the new value, and preventDefault reverts it
    if(!e.currentTarget.checked && this.currentItemNotes > 0){
      e.preventDefault();
      let msg = 'Because a note is labeled "Misspelled," it cannot be unchecked directly. To remove the check, you must delete all notes labeled as "Misspelled".'
      alert(msg)
      return false;
    }

    const misspelledOptionsContainer  = document.getElementById('misspelled-options')
    const misspelledOptionsCheckboxes = misspelledOptionsContainer.querySelectorAll('.js-misspelled-option-checkboxes');
    const misspelledFieldsContainer   = document.querySelector('#js-misspelled-fields-container')

    // handling cursor focus by hotkeys   
    let correctedOttomanSpellingFields = $('.js-corrected-ottoman-field');

    if (e.currentTarget.checked) {
      // this.showNotesWithAdditionalFlags({ created_on_type_o_misspelled: true })
      misspelledOptionsContainer.classList.remove('d-none');
      misspelledFieldsContainer.classList.remove('d-none')
      //hiding add alternative button of simple ottoman value if item is misspelled.
      $('.js-add-alternative-btn.js-simple-add-alt-btn').addClass('d-none');
      this.toBeCheckedCheckBoxTarget.checked = false
      correctedOttomanSpellingFields.addClass('js-text-field');
      // Replicate ottoman simple to corrected value automatically
      this.ottoInputCorrectedTarget.value = this.ottoInputTarget.value;
      this.handleVisibilityOfOttoSpellingToggles(true)
      this.handleVisibilityOfMarkedAsPrimaryRadioBtnFromPrimaryFields(true)
      this.uncheckTheCheckedComponentToggles();
      this.clickMarkedAsPrimaryRadioBtnOfCorrectedOttoField();
      // this.convertPrimaryAlternatesToCorrectedAlternates()
    } else {
      misspelledOptionsContainer.classList.add('d-none');
      misspelledFieldsContainer.classList.add('d-none')
      //adding add alternative button of simple ottoman value if item is not misspelled.
      $('.js-add-alternative-btn.js-simple-add-alt-btn').removeClass('d-none');     
      correctedOttomanSpellingFields.removeClass('js-text-field');
      // Uncheck all checkboxes inside the misspelled-options container
      misspelledOptionsCheckboxes.forEach(checkbox => {
        checkbox.checked = false;
      });
      this.handleVisibilityOfOttoSpellingToggles(false)
      this.handleVisibilityOfMarkedAsPrimaryRadioBtnFromPrimaryFields(false)
      this.clickMarkedAsPrimaryRadioBtnOfPrimaryOttoField()
      // this.removeCorrectedAlternatesIfPresent()
    }
  }

  // On selecting whether an item is misspelled, additional checkboxes are displayed and notes form is made visible.
  triggerTypeLMisspelled(e) {
    const misspelledOptions = document.getElementById('misspelled-options')
    const misspelledOptionsCheckboxes = misspelledOptions.querySelectorAll('.js-misspelled-option-checkboxes');

    if (e.currentTarget.checked) {
      // this.showNotesWithAdditionalFlags({ created_on_type_l_misspelled: true })
      // TODO: Fix: this.toBeCheckedCheckBoxTarget.checked = false
      misspelledOptions.classList.remove('d-none');
    } else {
      misspelledOptions.classList.add('d-none');
      misspelledOptionsCheckboxes.forEach(checkbox => {
        checkbox.checked = false;
      });
    }
  }

  showNotesWithAdditionalFlags({ created_on_type_o_to_be_checked = false, created_on_type_o_misspelled = false, created_on_type_l_to_be_checked = false, created_on_type_l_misspelled = false } ){
    let stagesElement = document.querySelector('.js-stages-controller');
    if(stagesElement){
      let stagesController = this.application.getControllerForElementAndIdentifier(
        stagesElement, "stages"
      );

      stagesController.showNotes({ created_on_type_o_to_be_checked: created_on_type_o_to_be_checked, created_on_type_o_misspelled: created_on_type_o_misspelled, created_on_type_l_to_be_checked: created_on_type_l_to_be_checked, created_on_type_l_misspelled: created_on_type_l_misspelled } )
    }
  }

  triggerToBeChecked(e) {
    if (this.typing_for != 'combined_word') {
      if (this.misspelledCheckBoxTarget.checked && this.currentItemNotes > 0) {
        let msg = 'Please delete notes with conflicting labels before changing the label.'
        alert(msg)
        e.currentTarget.checked = false
        return false
      }
    }

    if(!e.currentTarget.checked && this.currentItemNotes > 0){
      e.preventDefault();
      let msg = 'Because a note is labeled "Checked," it cannot be unchecked directly. To remove the check, you must delete all notes labeled as "Checked"'
      alert(msg)
      return false;
    }

    // If to be checked is selected, then system open notes automatically for ther user to create a new note
    if (this.typing_for != 'combined_word') {
      const misspelledOptionsContainer = document.getElementById('misspelled-options')
      const misspelledOptionsCheckboxes = misspelledOptionsContainer.querySelectorAll('.js-misspelled-option-checkboxes');
      let misspelledFieldsContainer = document.getElementById('js-misspelled-fields-container')

      if (e.currentTarget.checked) {
        // this.showNotesWithAdditionalFlags({ created_on_type_o_to_be_checked: true });
        this.misspelledCheckBoxTarget.checked = false

        misspelledOptionsContainer.classList.add('d-none');
        misspelledFieldsContainer.classList.add('d-none')
        // Uncheck all checkboxes inside the misspelled-options container
        misspelledOptionsCheckboxes.forEach(checkbox => {
          checkbox.checked = false;
        });

      }
    }
  }

  triggerTypelToBeChecked(e){
    const misspelledOptions = document.getElementById('misspelled-options')
    if (e.currentTarget.checked) {
      // this.showNotesWithAdditionalFlags({ created_on_type_l_to_be_checked: true });
      // TODO: Wrong implementation. Fix. misspelledOptions.classList.add('d-none')
      // this.misspelledCheckBoxTarget.checked = false
    }
  }

  // getFontSize(fontSizePercent) {
  //   // type-field is the common class used for typing, and it is setting the height of field
  //   const maxFontSize = document.getElementsByClassName('type-field')[0].clientHeight * 1.15;
  //   return maxFontSize * fontSizePercent / 100;
  // }

  setFocusedField(e){
    this.lastFocusedField = e.currentTarget;
  }

  // TODO: fetch harakat characters from rails because we have a constant in rails side too
  // this method removes the harakats from the text in the text field when root toggle is toggled on/off
  removeHarakatCharsFromTextFieldForRoot(e) {
    // early return because this functionality is not required anymore, but the code is kept in case if it is needed again in future.
    // early return reason -> https://trello.com/c/N0plbAvz/1473-remove-restriction-on-alternate-values-for-harakat
    return
    const rootToggle = e.currentTarget;
    const textField = rootToggle.closest('.entries-input').querySelector('.js-otto-field-for-alternates');
    if (false && textField) {
      let textValue = textField.value;
      const harakatChars = ['َ', 'ِ', 'ٰ', 'ٖ', 'ُ', 'ٌ', 'ْ', 'ّ', 'ً', 'ٍ'];
      const regex = new RegExp(harakatChars.map(char => '\\' + char).join('|'), 'g');
      textValue = textValue.replace(regex, '');
      textField.value = textValue;
    }
  }

  // TODO: fetch harakat characters from rails because we have a constant in rails side too
  filterValidCharacters(e) {
    let str = e.currentTarget.value || '';
    let savedStr = str;
    let savedCursorPosition = e.currentTarget.selectionStart;
    str = this.removeConsecutiveSpaces(str)
    // we dont allow this specific combination in type-l
    str = str.replace('ھ ', 'ھ')
    str = str.replace('ھ\u200C', 'ھ')
    str = str.replace('ە\u200C', 'ە')
    let stringCharacters = str.split('')
    
    let allowedChars = []
    if (e.currentTarget.classList.contains('js-otto-field-for-alternates')){
      if (e.currentTarget.parentElement.querySelector('.js-root-letter-toggle')?.checked) {
        allowedChars = ['ء', 'ب', 'ت', 'ث', 'ج', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ك', 'ل', 'م', 'ن', 'و', 'ه', 'ی'];
      } else if (false && e.currentTarget.parentElement.querySelector('.js-root-toggle-typeo-alternates').checked) {
        // this else if is not required anymore the code is kept in case if it is needed again in future // https://trello.com/c/N0plbAvz/1473-remove-restriction-on-alternate-values-for-harakat
        const harakatChars = ['َ', 'ِ', 'ٰ', 'ٖ', 'ُ', 'ٌ', 'ْ', 'ّ', 'ً', 'ٍ'];
        let allowedCharsForRoot = this.validOttomanCharactersForAlternates;
        allowedChars = allowedCharsForRoot.filter(char => !harakatChars.includes(char));
      } else {
        allowedChars = this.validOttomanCharactersForAlternates
      }
    } else if (e.currentTarget.classList.contains('js-otto-field')) {
      allowedChars = this.validOttomanCharacters
    } else{
      allowedChars = this.validLatinCharacters
    }

    let validStr   = stringCharacters.filter((char) => allowedChars.includes(char)).join('');
    e.currentTarget.value = validStr;
    
    if(savedStr !== validStr){
      e.currentTarget.setSelectionRange(savedCursorPosition - 1, savedCursorPosition - 1); // Setting cursor to current position after setting filtered value
      e.currentTarget.focus()
    }
    
    // if(savedStr != str){ // it means words are filtered
    //   savedCursorPosition--;
    // }
    // // Setting placement of cursor
    // e.currentTarget.setSelectionRange(savedCursorPosition, savedCursorPosition); // Move cursor after apostrophe

    let wrongChars = stringCharacters.filter((char) => !allowedChars.includes(char));
    if(wrongChars.length > 0){
      // this.addFlashMessage(`Invalid character(s) [${wrongChars}] entered.`)
    }
  }

  // Remove consecutive space or zero with space, keeps 1
  removeConsecutiveSpaces(str) {
    let pattern = /( |\u200C)+/g; // Space and zero widht character. we keep one of these characters, if they appear consecutive
    let cleanedString = str.replace(pattern, (match, group) => group[0]);
    return cleanedString;
  }

  moveToPrevEntry(e){
    typing_form.submit(e, "prev");
  }

  moveToNextEntry(e){
    typing_form.submit(e, "next");
  }

  makeClickedItemActive(e) {
    $(".list-group-item").removeClass("active").removeClass("js-current-selected-item"); // Remove active and js-current-selected-item classes from all list items
    var closestAnchor = $(e.currentTarget).closest('a.list-group-item');  // Find the closest ancestor anchor tag (if any)
    if (closestAnchor.length > 0) { // Add active and js-current-selected-item classes to the closest anchor, js-current-selected-item for scrolling 
      closestAnchor.addClass("active js-current-selected-item");
    }
  }

  makePassedItemActive(itemId){
    $(".list-group-item").removeClass("active").removeClass("js-current-selected-item"); // Remove active and js-current-selected-item classes from all list items
    $(`#${itemId}`).addClass("active js-current-selected-item");
  }

  changeIconToSubmitted(itemId) {
    // getting the div containing icon
    const iconContainer = $(`#${itemId} .js-item-submission-status-icon-container`);
    if(iconContainer.length > 0){
      const icon = iconContainer.find('svg.feather.feather-edit-3');
      if(icon.length > 0){
        icon.replaceWith(feather.icons['check'].toSvg());
        // as the svg icon is replaced with updated icon, getting new svg icon to add classes to it
        const newIcon = iconContainer.find('svg.feather.feather-check');
        newIcon.addClass("ms-3 d-inline-block rounded-circle shadow-sm bg-success border border-2");
      }
    }
  }

  // if against item/image ottoman/latin value is present/exists then this method marks it green
  makePassedItemSubmitted(itemId){
    $(`#${itemId}`).removeClass("list-item-entry");
    $(`#${itemId}`).addClass("submitted-list-item-entry");
    this.changeIconToSubmitted(itemId);      
  }

  changeIconToNonSubmitted(itemId) {
    // getting the div containing icon
    const iconContainer = $(`#${itemId} .js-item-submission-status-icon-container`);
    if (iconContainer.length > 0) {
      const icon = iconContainer.find('svg.feather.feather-check');
      if (icon.length > 0) {
        icon.replaceWith(feather.icons['edit-3'].toSvg());
         // as the svg icon is replaced with updated icon, getting new svg icon to add classes to it
        const newIcon = iconContainer.find('svg.feather.feather-edit-3');
        newIcon.addClass("ms-3 d-inline-block shadow-sm");
      }
    }
  }

  // if against item/image ottoman/latin value is NOT present/exists then this method marks it non-green
  makePassedItemNonSubmitted(itemId){
    $(`#${itemId}`).removeClass("submitted-list-item-entry");
    $(`#${itemId}`).addClass("list-item-entry");
    this.changeIconToNonSubmitted(itemId);
  }

  showComponentTogglesSelectionErrorIfRequired() {
    if (this.isTypingOttomanStage()) {
      if ($('input.js-marked-as-component-toggle:checked').length > 0 && $('input.js-marked-as-component-toggle:checked').length < 2){
        showFlashMessage('A Compound consists of at least two Components. Please mark at least one more value as a Component, or remove the Component label from the existing value.', 'alert');
        return true
      }
    }
    return false
  }

  showTogglesSelectionErrorIfRequired() {
    if (this.isTypingOttomanStage() && this.typing_for != 'combined_word') {
      const misspelledToggle  = document.getElementById('chk-to-be-misspelled');
      const textFieldsContainer = misspelledToggle.checked
          ? document.querySelector('#js-misspelled-fields-container')
          : document.querySelector('#js-primary-fields-container');

      let showError = false;
      textFieldsContainer.querySelectorAll('.js-entries-input').forEach(entryInput => {
        if (showError) return; // Skip the further iterations if error case already identified

        let currentWordSpellingsLinkingtoggles = entryInput.querySelectorAll('.js-current-word-spellings-relationship-toggle');
        let entryWordSpellingsLinkingtoggles = entryInput.querySelectorAll('.js-entry-word-spellings-relationship-toggle');
        let isAnyCurrentWordToggleOn = true;
        let isAnyEntryWordToggleOn = true;
        
        // check if any current word linking toggle is selected 
        if (currentWordSpellingsLinkingtoggles.length > 0) {
          isAnyCurrentWordToggleOn = Array.from(currentWordSpellingsLinkingtoggles).some(toggle => toggle.checked);
        }

        // check toggles selection of only 'primary' spelling/entry spelling linking 
        if(entryInput.querySelector('.js-primary-entry-linkage')){
          isAnyEntryWordToggleOn = Array.from(entryWordSpellingsLinkingtoggles).some(toggle => toggle.checked);
        }

        // show error in case current spelling all toggles or entry word spelling all toggles are unchecked
        if (!(isAnyCurrentWordToggleOn && isAnyEntryWordToggleOn)) {
          showError = true;
        }
      });

      if (showError) {
          // showFlashMessage('At least one tag must be selected for each value.', 'alert');
          // return true;
      }
    }

    return false;
  }

  showMisspelledErrorIfRequired(){
    if (this.isTypingOttomanStage() && this.typing_for != 'combined_word' && this.misspelledCheckedWithNoOptions()){
      showFlashMessage('The item has been identified as <b>Misspelled</b>. To proceed, kindly review and select one of the following options: <b>Letters, Harakat or Complicated</b>', 'alert')
      return true;
    } else {
        return false;
    }
  }

  moveToClickedItem(e){
    if (this.showMisspelledErrorIfRequired()){
      e.preventDefault(); // to prevent form submission // to prevent moving to next item and making it active in typing sidebar list 
      return;
    }
    // The code has been commented out as it is temporarily not required.
    // if (this.showTogglesSelectionErrorIfRequired()){
    //   e.preventDefault(); // to prevent form submission // to prevent moving to next item and making it active in typing sidebar list 
    //   return;
    // }

    if (this.showComponentTogglesSelectionErrorIfRequired()){
      e.preventDefault(); // to prevent form submission // to prevent moving to next item and making it active in typing sidebar list 
      return;
    }

    this.makeClickedItemActive(e);
    let itemId   = e.currentTarget.dataset.itemId;
    let itemType = e.currentTarget.dataset.itemType;
    typing_form.submit(e, null, itemId, itemType);
  }

  submit(e, moveDirection = "next", itemId = null, itemType = null) {
    e.preventDefault();
    e.stopPropagation();
    
    if (this.showMisspelledErrorIfRequired()){
      return;
    }
    // The code has been commented out as it is temporarily not required.
    // if (this.showTogglesSelectionErrorIfRequired()){
    //   return;
    // }

    if (this.showComponentTogglesSelectionErrorIfRequired()){
      return;
    }

    let formElement = typing_form.formTarget;
    if (moveDirection == "prev") {
      let inp = document.createElement('input')
      inp.type = 'hidden'
      inp.name = 'redirect_prev'
      inp.value = 'true'
      formElement.appendChild(inp)
    }

    // when item is clicked from sidebar list
    if (itemId && itemType) {
      // Item id
      let inp = document.createElement('input')
      inp.type = 'hidden'
      inp.name = 'item_id'
      inp.value = itemId
      formElement.appendChild(inp)
      // Item Type 
      let inp2 = document.createElement('input')
      inp2.type = 'hidden'
      inp2.name = 'item_type'
      inp2.value = itemType
      formElement.appendChild(inp2)
    }

    let bookMarkTogglerInput = document.createElement('input')
    bookMarkTogglerInput.type = 'hidden'
    bookMarkTogglerInput.name = 'showOnlyBookmarkedItems'
    bookMarkTogglerInput.value = isEnabledDisplayOnlyBookmarkedItems
    formElement.appendChild(bookMarkTogglerInput)

    //formElement.submit() // here instead of formElement.submit() we are clicking Save submit button to avoid list elements reload(which slows down the page) & to make ajax call work instead. 
    if (this.formSubmitButtonTarget) {
      this.formSubmitButtonTarget.click();
    }
  }

  isTypingLatinStage(){
    return this.typing_stage == 'type_l'
  }

  isTypingOttomanStage(){
    return this.typing_stage == 'type_o'
  }

  // model key to add params according to back-end model
  modelKey(){
    let key = '';

    if (this.typing_for == 'entry'){
      key = 'entry'
    } else if (this.typing_for == 'subentry'){
      key = 'subentry'
    } else if (this.typing_for == 'other'){
      key = 'other_word'
    }
    return key;
  }

  focusToAlternateTranscription(e) {
    let newAlternateFieldContainer = document.querySelector('.js-new-added-alternate-field-container');
    if (newAlternateFieldContainer) {
      let inputField = newAlternateFieldContainer.querySelector('input');
      if (inputField) {
        inputField.focus();
        this.lastFocusedField = inputField;
      }
    }

    e.currentTarget.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'nearest' });
    // removing the class here so that when next time new alternate field is created, it should be available only on that newest alternate field 
    newAlternateFieldContainer.classList.remove('js-new-added-alternate-field-container');
  }

  addAppostrphe(e){
    // TODO: Dont directly use from buttons display html. Data attributes can be used for this
    let apostrophe = e.srcElement.innerHTML;
    const cursorPosition = this.lastFocusedField.selectionStart;
    const currentValue = this.lastFocusedField.value;
    
    const newValue =
      currentValue.substring(0, cursorPosition) +
      apostrophe +
      currentValue.substring(cursorPosition);
    
    this.lastFocusedField.value = newValue;
    this.lastFocusedField.setSelectionRange(cursorPosition + 1, cursorPosition + 1); // Move cursor after apostrophe
    this.lastFocusedField.focus();
  }

  clearOttomanField(e){
    if (e.currentTarget.classList.contains('js-simple-ottoman-field')) {
      this.ottoInputTarget.value = ''
    } else {
      this.ottoInputCorrectedTarget.value = ''
    }
  }

  hotKeys(e) {
    if (e.keyCode == 13 && isLatinHistoryModalOpen) {
      e.preventDefault()
    }

    if(this.isNotesModalOpen || context_modal || preview_modal || submit_page_set_modal || pageset_approve_or_return_modal || isLatinHistoryModalOpen){
      return true;
    }

    const eventObject = window.event ? event : e
    const enter       = 13
    const up          = 38
    const down        = 40
    const alt         = 18
    const ctrl        = 17
    const one         = 49
    const two         = 50
    const three       = 51
    const left        = 37
    const right       = 39
    const m           = 77

    const capturedKeys = [enter, up, down, alt, one, two, three, left, right, ctrl, m]
    
    if (eventObject.keyCode == enter && isLatinHistoryModalOpen) {
      e.preventDefault()
    }

    // to avoid changing behaviour for rest of the keys
    if (!capturedKeys.includes(eventObject.keyCode) || 
        (eventObject.keyCode === three && this.typing_stage === "type_l") ||
        eventObject.keyCode === three && document.activeElement.classList.contains('js-index-badge')) {
      return;
    }

    if ([left, right].includes(eventObject.keyCode) && !preview_modal) { //to allow left/right arrow keys to work in text field in typeo/typel
      return true;
    }

    if (!eventObject.ctrlKey && eventObject.keyCode == m) {
      return true
    }

    // if the key '1' is pressed in the text field of ottoman spellings on type_o stage, then toggle the state of Turkish toggle
    if (eventObject.keyCode === one && !eventObject.altKey && !eventObject.ctrlKey && !eventObject.shiftKey && this.typing_stage === 'type_o'){
      const turkishToggle = event.target
        .closest('.js-entries-input')
        ?.querySelector('.js-otto-spellings-lang-toggles-container .js-marked-as-turkish-toggle');
      if (turkishToggle) {
        e.preventDefault();
        turkishToggle.checked = !turkishToggle.checked;
      } else {
        return
      }  
    }

    // Check if the '2' key is pressed without any alt key
    if (eventObject.keyCode === two && !eventObject.altKey && this.typing_stage === 'type_o') {
      const persianToggle = event.target
        .closest('.js-entries-input')
        ?.querySelector('.js-otto-spellings-lang-toggles-container .js-marked-as-persian-toggle');
      if (persianToggle) {
        e.preventDefault();
        persianToggle.checked = !persianToggle.checked;
      } else {
        return
      }     
    }

    // if the key '3' is pressed in the text field of alternate ottoman on type_o stage, then toggle the state of arabic toggle
    if (eventObject.keyCode === three && this.typing_stage === 'type_o') {
      // getting the arabic toggle, through the container of the target input field
      const arabicLetterToggle = event.target
        .closest('.js-entries-input')
        ?.querySelector('.js-otto-spellings-lang-toggles-container .js-marked-as-arabic-toggle');
      if (arabicLetterToggle) {
        e.preventDefault();
        arabicLetterToggle.checked = !arabicLetterToggle.checked;
      } else {
        return
      }
    }

    if (eventObject.keyCode === enter && typeo_form && typeo_form.selectedSpellingCheckboxes.length === 2) {
      typeo_form.handleSpellingsLinkingThroughArrows();
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    let moveDirection = 'next'
    // if Alt +1 or Alt +2 for apostrophe
    if (eventObject.altKey && (eventObject.keyCode == one || eventObject.keyCode == two)) {
      if (this.typing_stage == 'type_l') {
        if (eventObject.altKey && eventObject.keyCode == one) {
          this.startQuoteBtnTarget.click()
        } else {
          this.endQuoteBtnTarget.click()
        }
      }    
    } else if(eventObject.shiftKey && eventObject.keyCode == enter) {
      let selectedText = window.getSelection().toString();
      // in type_o add simple ottomans alternate spelling values if in misspelled case and focus is not on simple ottoman value
      // in type_l add alterative entry manually if text is not selected
      if ((this.typing_stage == 'type_o' && !(this.hasMisspelledCheckBoxTarget && this.misspelledCheckBoxTarget.checked && this.lastFocusedField.classList.contains('js-simple-ottoman-alternative-value'))) || 
         (this.typing_stage == 'type_l' && !selectedText)) {
        this.addAlternativeEntryManually();
      } else {
        this.copySelectedTextToNextTranscriptionField(selectedText);
      }
    } 
    // alt + enter key to focus on next text field
    else if ((eventObject.altKey && eventObject.keyCode == enter)){
      this.moveToNextTextField();
    } 
    /* ctrl + enter and up key for move to previous item and else for moving to next item*/
    else if((eventObject.ctrlKey && eventObject.keyCode == enter) || eventObject.keyCode == up || eventObject.keyCode == down || eventObject.keyCode == enter && !isLatinHistoryModalOpen) {
      /* ctrl + enter */
      if (eventObject.ctrlKey && eventObject.keyCode == enter) {
        moveDirection = "prev";
      } else if (eventObject.keyCode == up) {
        moveDirection = "prev";
      } else { // Enter, down
        moveDirection = "next";
      }

      // when current item container of badge is not in edit mode
      if (this.currentItemIndexContainerTarget.contentEditable == 'inherit' || this.currentItemIndexContainerTarget.contentEditable == 'false' ) {
        typing_form.submit(e, moveDirection)
      } else {
        let editToWrittenEntryItem = this.currentItemIndexContainerTarget
        if ((!/[0-9]/.test(event.key) && event.keyCode !== 13)|| (event.keyCode === 13 && editToWrittenEntryItem.innerText == '')) {
          event.preventDefault();
        } else if (event.keyCode === 13 && /[0-9]/.test(editToWrittenEntryItem.innerText)) {
          this.editToWrittenEntryItem();
        }
      }
    } else if(eventObject.keyCode == m && eventObject.ctrlKey) {
      this.contextViewBtnTarget.click()
    } //else if(eventObject.keyCode == enter && typeo_form && typeo_form.selectedCheckBoxes.length === 2) {
      //debugger
        //typeo_form.handleShiftKeyPress(e)
    //}
  }

  // For page_set
  // TODO: should be separate preview controller
  preview(e) {
    let stageUrl = this.previewUrlForStage(this.typing_stage, e.currentTarget.dataset.pageSetId)
    let data = {
      current_item_id: this.current_item_id,
      current_item_type: this.typing_for
    };
    $.ajax({
      url: stageUrl,
      type: 'GET',
      data: data
    });
  }

  previewUrlForStage(typing_stage, setId) {
    switch(typing_stage){
    case 'type_o':
      return Routes.preview_type_o_set_path({ id: setId })
      break
    case 'type_l':
      return Routes.preview_type_l_set_path({ id: setId })
      break
    }
  }
  
  // Why is it async
  async toggleThumbnailsVisibility(e){
    // TODO: Use separate classes for css and js
    let areThumbnailsVisible = (document.getElementsByClassName("hide_thumbnail").length == 0);
    let updatedThumbnailFlag = !areThumbnailsVisible;
    
    await Rails.ajax({
      type: "PATCH",
      url: "/user_settings/toggle_thumbnail_preview.js",
      data: `thumbnail_flag=${updatedThumbnailFlag}`,
      success: function(){
        location.reload();
      }
    });
  }

  misspelledCheckedWithNoOptions(){
    let misspelledOptionsContainer = document.getElementById('misspelled-options')
    let misspelledOptionsCheckboxes = misspelledOptionsContainer.querySelectorAll('.js-misspelled-option-checkboxes');
    let anyChecked = false;
    misspelledOptionsCheckboxes.forEach(checkbox => {
      if(checkbox.checked){
        anyChecked = true
      }
    });

    return (this.misspelledCheckBoxTarget.checked && !anyChecked)
  }

  // this method removed notes related styling from typing edit area
  removeNotesStyling(e){
    let currentItemNotesCount = this.currentItemNotes;

    if (currentItemNotesCount == 0 && 
        e.target.dataset.noteStage == this.typing_stage){  
      this.removeNotesStylingInTypingEditArea();
    }
  }

  removeNotesStylingInTypingEditArea(){
    $('.js-item-container').removeClass('border border-danger border-2');
    $('.js-progress-bar-slider').removeClass('bg-danger');
    $('.js-progress-bar').css('background-color', '');
  }

  // this method adds notes related styling from typing edit area
  addNotesStyling(){
    this.setCurrentItemNotes(this.currentItemNotes + 1);
    this.displayNotesStylingInTypingEditArea();
  }

  displayNotesStylingInTypingEditArea(){
    $('.js-item-container').addClass('border border-danger border-2');
    $('.js-progress-bar-slider').addClass('bg-danger');
    $('.js-progress-bar').css('background-color', '#FFB6C1');
  }

  // this method updates type_o/type_l alternative values assigned numbers
  updateAlternativeValueAssignedNumber(e) {
    const clickedBtn           = e.currentTarget;
    const clickedBtnIdentifier = parseInt(clickedBtn.getAttribute('data-add-alternative-btn-identifier'));

    // Updating identifier numbers of unclicked add alternative btns
    // also setting assigned values of hidden fields
    this.updateAddAltBtnsAndAssignedValueFieldsAfterSelectedField(clickedBtnIdentifier);
    
    // Setting Identifier numbers of new rendered add alternative btns
    // also setting assigned values of hidden fields
    this.setNewAddAltBtnsAndAssignedValueFields(clickedBtn, clickedBtnIdentifier); 
  }

  // Helper function to update identifier numbers of unclicked add alternative btns
  updateAddAltBtnsAndAssignedValueFieldsAfterSelectedField(selectedFieldIdentifier){
    const addAlternativeBtns = $('.js-add-alternative-btn');
    addAlternativeBtns.each((index, addAlternativeBtn) => {
      let unclickedBtnPrevIdentifier = parseInt(addAlternativeBtn.getAttribute('data-add-alternative-btn-identifier'));
      let updatedIdentifier          = unclickedBtnPrevIdentifier + 1;
      let isAddAlternativeBottomBtn  = addAlternativeBtn.getAttribute('data-from-add-alternative-button');

      if (unclickedBtnPrevIdentifier <= selectedFieldIdentifier && !isAddAlternativeBottomBtn) return;
      
      this.setAddAltBtnIdentifier(addAlternativeBtn, updatedIdentifier);
      
      // Updating Assigned Numbers Hidden Fields Value if unclicked btn is not bottom button
      if(!isAddAlternativeBottomBtn){
        this.updateAltEntryAssignedNumber(unclickedBtnPrevIdentifier, updatedIdentifier);
      }
    });
  }

  // Helper function to set identifier number and assigned Value of new rendered add alternative btn
  setNewAddAltBtnsAndAssignedValueFields(clickedBtn, clickedBtnIdentifier){
    const newAddAlternativeBtn               = document.querySelector(`[data-add-alternative-btn-identifier='-1']`);
    const isFromBottomAlternativeBtn         = clickedBtn.getAttribute('data-from-add-alternative-button');
    const updatedAddAlternativeBtnIdentifier = clickedBtnIdentifier + 1;
    
    // checking if clicked btn is bottom add alternative btn
    if (isFromBottomAlternativeBtn == 'true') {
      // set new btn identifier and assigned value equal to clicked btn and then increment clicked btn identifier number by 1 
      // because bottom button is at the most bottom of entries that's why it's identifier value will be highest 
      this.setAddAltBtnIdentifier(newAddAlternativeBtn, clickedBtnIdentifier);
      this.updateAltEntryAssignedNumber(-1, clickedBtnIdentifier);
      this.setAddAltBtnIdentifier(clickedBtn, updatedAddAlternativeBtnIdentifier);
    } else {
      // set new alt btn identifier and assigned value one more than clicked btn
      this.setAddAltBtnIdentifier(newAddAlternativeBtn, updatedAddAlternativeBtnIdentifier);
      this.updateAltEntryAssignedNumber(-1, updatedAddAlternativeBtnIdentifier);
    }
  }

  // Setter function to set identifier value of all alternative btns
  setAddAltBtnIdentifier(addAlternativeBtn, updatedIdentifier) {
    addAlternativeBtn.setAttribute('data-add-alternative-btn-identifier', updatedIdentifier);
  }

  // Helper function to update Hidden Field Assigned Number
  updateAltEntryAssignedNumber(prevAssignedValue, updatedAssignedValue ){
    const assignedNumberHiddenField = $(`.assigned-number-${prevAssignedValue}`);

    if (!assignedNumberHiddenField.length) return;

    if (assignedNumberHiddenField.length > 1){
      //updating second value as previously changed assigned_value would become equal to the next value
      assignedNumberHiddenField[1].value = updatedAssignedValue;
      assignedNumberHiddenField[1].setAttribute('class', `assigned-number-${updatedAssignedValue} js-otto-spelling-assigned-number`);
    } else {
      assignedNumberHiddenField[0].value = updatedAssignedValue;
      assignedNumberHiddenField[0].setAttribute('class', `assigned-number-${updatedAssignedValue} js-otto-spelling-assigned-number`);
    }
  }

  getCurrentOttoFieldToggle(e, toggleName) {
    const toggleSelectors = {
      rootToggle: '.js-marked-as-root-toggle',
      singularToggle: '.js-marked-as-singular-toggle',
      derivedToggle: '.js-marked-as-derived-value-toggle',
      pluralToggle: '.js-marked-as-plural-value-toggle',
      relatedToggle: '.js-marked-as-related-value-toggle',
      oppositeToggle: '.js-marked-as-opposite-value-toggle',
      synonymToggle: '.js-marked-as-synonym-value-toggle',
      alternateToggle: '.js-marked-as-alternate-value-toggle'
    };

    const toggleSelector  = toggleSelectors[toggleName];
    const toggleContainer = e.target.closest('.js-entries-input');

    return toggleSelector ? toggleContainer.querySelector(toggleSelector) : null;
  }

  // RULES FOR TOGGLE BUTTONS & Dependencies Between Labels:

  // If a word is singular, it must be a Root, but not every Root is singular.
  // If a word is plural, it must be Derived, but not every Derived is plural.
  // If a word is opposite, it must be Related, but not every Related is opposite.

  // Turning Off Dependencies:

  // If Root is turned off, Singular must also turn off automatically.
  // If Derived is turned off, Plural must also turn off automatically.
  // If Related is turned off, Opposite must also turn off automatically.

  // Turning On Dependencies:

  // If Singular is turned on, Root must automatically turn on.
  // If Plural is turned on, Derived must automatically turn on.
  // If Opposite is turned on, Related must automatically turn on.

  // Maintaining Independence:

  // When Singular and Root are both active, turning off Singular must not turn off Root.
  // When Plural and Derived are both active, turning off Plural must not turn off Derived.
  // When Opposite and Related are both active, turning off Opposite must not turn off Related.
  
  // TODO: move these methods in a separate controller
  
  // Rules:
  // 1_ If Root is turned On, Derived(Drv.), Plural(Plr.), Related(Rel.) & Opposite(Opp.) should be turned off
  // 2_ If Root is turned Off, Singular (Sing.) must also turn off automatically.
  // Note: Turning off Opposite due to an intermediate dependency of Opp. on Rel.
  updateRootRelatedOtherTogglesStates(e) {
    const rootToggleCheckbox = e.target;
    const isChecked          = rootToggleCheckbox.checked;

    let markedAsDerivedToggle  = this.getCurrentOttoFieldToggle(e, 'derivedToggle')
    let markedAsPluralToggle   = this.getCurrentOttoFieldToggle(e, 'pluralToggle')
    let markedAsRelatedToggle  = this.getCurrentOttoFieldToggle(e, 'relatedToggle')
    let markedAsOppositeToggle = this.getCurrentOttoFieldToggle(e, 'oppositeToggle')
    let markedAsSingularToggle = this.getCurrentOttoFieldToggle(e, 'singularToggle')

    if (isChecked) {
      markedAsDerivedToggle.checked  = false;
      markedAsPluralToggle.checked   = false;
      markedAsRelatedToggle.checked  = false;
      markedAsOppositeToggle.checked = false;
    } else {
      markedAsSingularToggle.checked = false;
    }
  }

  // Rules: 
  // 1_ If Singular(Sing.) is turned On, the Root should be turned on automatically(bcz all Sing. are Roots.)
  // 2_ If Singular(Sing.) is turned On, turn off the Derived(Drv.), Plural(Plr.), Related(Rel.) & Opposite(Opp.) should be turned off
  // Note: Turning off Opposite due to an intermediate dependency of Opp. on Rel.
  updateSingularRelatedOtherTogglesStates(e) {
    const singularToggleCheckbox = e.target;
    const isChecked              = singularToggleCheckbox.checked;

    let markedAsRootToggle     = this.getCurrentOttoFieldToggle(e, 'rootToggle')
    let markedAsDerivedToggle  = this.getCurrentOttoFieldToggle(e, 'derivedToggle')
    let markedAsPluralToggle   = this.getCurrentOttoFieldToggle(e, 'pluralToggle')
    let markedAsRelatedToggle  = this.getCurrentOttoFieldToggle(e, 'relatedToggle') 
    let markedAsOppositeToggle = this.getCurrentOttoFieldToggle(e, 'oppositeToggle')

    if (isChecked) {
      markedAsRootToggle.checked     = true;
      markedAsDerivedToggle.checked  = false;
      markedAsPluralToggle.checked   = false;
      markedAsRelatedToggle.checked  = false;
      markedAsOppositeToggle.checked = false;
    }
  }

  // Rules:
  // 1_ If Derived(Drv.) is turned On, Root, Singular(Sing.), Related(Rel.) & Opposite(Opp.) should be turned off.
  // 2_ If Derived(Drv.) is turned Off, Plural must also turn off automatically.
  // Note: Turning off Opposite due to an intermediate dependency of Opp. on Rel.
  updateDerivedRelatedOtherTogglesStates(e) {
    const derivedToggleCheckbox = e.target;
    const isChecked             = derivedToggleCheckbox.checked;

    let markedAsRootToggle     = this.getCurrentOttoFieldToggle(e, 'rootToggle')
    let markedAsSingularToggle = this.getCurrentOttoFieldToggle(e, 'singularToggle')
    let markedAsRelatedToggle  = this.getCurrentOttoFieldToggle(e, 'relatedToggle') 
    let markedAsOppositeToggle = this.getCurrentOttoFieldToggle(e, 'oppositeToggle')
    let markedAsPluralToggle   = this.getCurrentOttoFieldToggle(e, 'pluralToggle')

    if (isChecked) {
      markedAsRootToggle.checked     = false;
      markedAsSingularToggle.checked = false;
      markedAsRelatedToggle.checked  = false;
      markedAsOppositeToggle.checked = false;
    } else {
      markedAsPluralToggle.checked = false;
    }
  }

  // Rules: 
  // 1_ If Plural(Plr.) is turned On, Derived(Drv.) should be turned on automatically
  // 2_ If Plural(Plr.) is turned On, Root, Singular(Sing.), Related(Rel.) & Opposite(Opp.) should be turned off.
  // Note: Turning off Opposite due to an intermediate dependency of Opp. on Rel.
  updatePluralRelatedOtherTogglesStates(e) {
    const pluralToggleCheckbox = e.target;
    const isChecked            = pluralToggleCheckbox.checked;

    let markedAsRootToggle     = this.getCurrentOttoFieldToggle(e, 'rootToggle')
    let markedAsSingularToggle = this.getCurrentOttoFieldToggle(e, 'singularToggle')
    let markedAsDerivedToggle  = this.getCurrentOttoFieldToggle(e, 'derivedToggle')
    let markedAsRelatedToggle  = this.getCurrentOttoFieldToggle(e, 'relatedToggle') 
    let markedAsOppositeToggle = this.getCurrentOttoFieldToggle(e, 'oppositeToggle')

    if (isChecked) {
      markedAsDerivedToggle.checked  = true;
      markedAsRootToggle.checked     = false;
      markedAsSingularToggle.checked = false;
      markedAsRelatedToggle.checked  = false;
      markedAsOppositeToggle.checked = false;
    }
  }

  // Rules:
  // 1_ If Opposite(Opp.) is turned On, Related(Rel.) should be turned on autimatically.
  // 2_ If Opposite(Opp.) is turned Off, Alternate(Alt.), Synonym(Syn.), Derived(Drv.) & Plural(Plr.) should be turned off due to intermediate dependencies.
  // Note: All opposites (Opp.) are Related (Rel.) at the same time (turning opposite on should turn on the related as well)
  updateOppositeRelatedOtherTogglesStates(e) {
    const oppositeToggleCheckbox = e.target;
    const isChecked              = oppositeToggleCheckbox.checked;

    let markedAsRelatedToggle   = this.getCurrentOttoFieldToggle(e, 'relatedToggle')
    let markedAsRootToggle      = this.getCurrentOttoFieldToggle(e, 'rootToggle')
    let markedAsSingularToggle  = this.getCurrentOttoFieldToggle(e, 'singularToggle')
    let markedAsAlternateToggle = this.getCurrentOttoFieldToggle(e, 'alternateToggle')
    let markedAsSynonymToggle   = this.getCurrentOttoFieldToggle(e, 'synonymToggle')    
    let markedAsDerivedToggle   = this.getCurrentOttoFieldToggle(e, 'derivedToggle')
    let markedAsPluralToggle    = this.getCurrentOttoFieldToggle(e, 'pluralToggle')
    
    if (isChecked) {
      markedAsRelatedToggle.checked   = true;
      markedAsRootToggle.checked      = false;
      markedAsSingularToggle.checked  = false;
      markedAsAlternateToggle.checked = false;
      markedAsSynonymToggle.checked   = false
      markedAsDerivedToggle.checked   = false
      markedAsPluralToggle.checked    = false;
    }
  }

  // Rules: 
  // 1_ If Related(Rel.) is turned On, Root, Singular(Sing.), Derived(Drv.), Plural(Plr.), Alternate(Alt.) & Synonym(Syn.) should be turned off.
  // 2_ If Related(Rel.) is turned Off, Opposite(Opp.) should be turned off automatically
  // Note: Opposite (Opp.) is dependent on related, if both are turned on, and user turns off Related (Rel.) then Opposite (Opp.) should be turned off automatically
  // Turning on Relted (Rel.) will not turn on the Opposite (Opp.) because Related is not dependent on Opposite
  // Note: here Singular(Sing.) is turned off due to intermediate dependency on Root, and Synonym is turned off due to intermediate dependency on Alternate(Alt.)
  updateRelatedOtherTogglesStates(e) {
    const relatedToggleCheckbox = e.target;
    const isChecked             = relatedToggleCheckbox.checked;

    let markedAsRootToggle      = this.getCurrentOttoFieldToggle(e, 'rootToggle')
    let markedAsSingularToggle  = this.getCurrentOttoFieldToggle(e, 'singularToggle')
    let markedAsDerivedToggle   = this.getCurrentOttoFieldToggle(e, 'derivedToggle')
    let markedAsPluralToggle    = this.getCurrentOttoFieldToggle(e, 'pluralToggle')
    let markedAsAlternateToggle = this.getCurrentOttoFieldToggle(e, 'alternateToggle')
    let markedAsSynonymToggle   = this.getCurrentOttoFieldToggle(e, 'synonymToggle')
    let markedAsOppositeToggle  = this.getCurrentOttoFieldToggle(e, 'oppositeToggle')

    if (isChecked) {
      markedAsRootToggle.checked      = false;
      markedAsSingularToggle.checked  = false;
      markedAsDerivedToggle.checked   = false;
      markedAsPluralToggle.checked    = false;
      markedAsAlternateToggle.checked = false;
      markedAsSynonymToggle.checked   = false;
    } else {
      markedAsOppositeToggle.checked = false;
    }
  }

  getCurrentTypingField(current_div){
    return current_div.closest('.js-entries-input');
  }

  handleEntryWordLinkageTogglesVisibility(e) {
    const unrelatedToggleCheckbox = e.target;
    const isChecked               = unrelatedToggleCheckbox.checked;
    const currentLinkingField     = this.getCurrentTypingField(unrelatedToggleCheckbox);
    const linkingTogglesFields    = currentLinkingField.querySelectorAll('.js-entry-linking-toggles-fields');
    
    let markedAsRootToggle      = this.getCurrentOttoFieldToggle(e, 'rootToggle')
    let markedAsSingularToggle  = this.getCurrentOttoFieldToggle(e, 'singularToggle')
    let markedAsDerivedToggle   = this.getCurrentOttoFieldToggle(e, 'derivedToggle')
    let markedAsPluralToggle    = this.getCurrentOttoFieldToggle(e, 'pluralToggle')
    let markedAsAlternateToggle = this.getCurrentOttoFieldToggle(e, 'alternateToggle')
    let markedAsSynonymToggle   = this.getCurrentOttoFieldToggle(e, 'synonymToggle')
    let markedAsOppositeToggle  = this.getCurrentOttoFieldToggle(e, 'oppositeToggle')
    let markedAsRelatedToggle   = this.getCurrentOttoFieldToggle(e, 'relatedToggle')

    if (isChecked) {
      markedAsRootToggle.checked      = false;
      markedAsSingularToggle.checked  = false;
      markedAsDerivedToggle.checked   = false;
      markedAsPluralToggle.checked    = false;
      markedAsAlternateToggle.checked = false;
      markedAsSynonymToggle.checked   = false;
      markedAsOppositeToggle.checked  = false;
      markedAsRelatedToggle.checked   = false;

      linkingTogglesFields.forEach(linkingToggleField => {
        linkingToggleField.classList.add('collapse')
      });
    } else {
      linkingTogglesFields.forEach(linkingToggleField => {
        linkingToggleField.classList.remove('collapse')
      });
    }
  }

  // Rules: 
  // 1_ If Alternate(Alt.) is turned On, Related(Rel.) & Opposite(Opp.) should be turned off.
  // 2_ If Alternate(Alt.) is turned Off, Synonym(Syn.) should be turned off automatically.
  // Note: Turning off Opposite due to an intermediate dependency of Opp. on Rel.
  updateAlternateRelatedOtherTogglesStates(e) {
    const alternateToggleCheckbox  = e.target;
    const isChecked                = alternateToggleCheckbox.checked;
    
    let markedAsRelatedToggle  = this.getCurrentOttoFieldToggle(e, 'relatedToggle')
    let markedAsOppositeToggle = this.getCurrentOttoFieldToggle(e, 'oppositeToggle')
    let markedAsSynonymToggle  = this.getCurrentOttoFieldToggle(e, 'synonymToggle')

    if (isChecked) {
      markedAsRelatedToggle.checked  = false;
      markedAsOppositeToggle.checked = false;
    } else {
      markedAsSynonymToggle.checked = false;
    }
  }

  // Rules: 
  // 1_ If Synonym(Syn.) toggle is turned On, Alternate(Alt.) toggle should be turned on automatically.
  // 2_ If Synonym(Syn.) toggle is turned On, Related(Rel.) should be turned off(Due to intermediate dependency on Alternate(Alt.))
  // 3_ If Synonym(Syn.) toggle is turned On, Opposite(Opp.) should be turned off(Due to intermediate dependency on Related(Rel.))
  // Note: Turning off Opposite due to an intermediate dependency of Opp. on Rel.
  updateSynonymRelatedOtherTogglesStates(e) {
    const synonymToggleCheckbox = e.target;
    const isChecked             = synonymToggleCheckbox.checked;

    let markedAsAlternateToggle = this.getCurrentOttoFieldToggle(e, 'alternateToggle')
    let markedAsRelatedToggle   = this.getCurrentOttoFieldToggle(e, 'relatedToggle')
    let markedAsOppositeToggle  = this.getCurrentOttoFieldToggle(e, 'oppositeToggle')

    if (isChecked) {
      markedAsAlternateToggle.checked = true;
      markedAsRelatedToggle.checked   = false;
      markedAsOppositeToggle.checked  = false;
    }
  }

  enableDragFromTextField(markedAsPrimaryRadioBtn) {
    const dragHandle = markedAsPrimaryRadioBtn.closest('.js-entries-input')?.querySelector('.js-drag-handle');
    dragHandle?.classList.remove("d-none");
    const otherValueContainer = markedAsPrimaryRadioBtn.closest('.js-other-value-container');
    otherValueContainer?.classList.remove("js-not-sortable"); 
    otherValueContainer?.classList.add("js-sortable-item");    
  }

  disableDragFromTextField(markedAsPrimaryRadioBtn) {
    const dragHandle = markedAsPrimaryRadioBtn.closest('.js-entries-input')?.querySelector('.js-drag-handle');
    dragHandle?.classList.add("d-none");
    const otherValueContainer = markedAsPrimaryRadioBtn.closest('.js-other-value-container');
    otherValueContainer?.classList.add("js-not-sortable");
    otherValueContainer?.classList.remove("js-sortable-item");
  }

  handleUiUpdateForPrimarySpellingChange(e) {
    const selectedRadioBtn = e.target;
    const fieldMarkedAsPrimaryContainer = selectedRadioBtn.closest('.js-entries-input');
    const misspelledCheckbox = document.querySelector("#chk-to-be-misspelled");
    
    const isMisspelledChecked = misspelledCheckbox?.checked;

    // const primarySpellingContainer = $(".js-primary-spellings-container");

    const primarySpellingContainer = isMisspelledChecked 
        ? $(".js-corrected-spellings-container") 
        : $(".js-primary-spellings-container");

    const primarySpellingRadioBtn = primarySpellingContainer[0]?.querySelector('.js-marked-as-primary-radio-btn');
    const primaryTogglesContainer = primarySpellingContainer[0]?.querySelector('.js-typing-field-bottom-toggles-container');

    this.resetEntryWordSpellingsExistingLinkages(fieldMarkedAsPrimaryContainer);
    this.handleSpellingLinkagesIcon(fieldMarkedAsPrimaryContainer);
    this.addPrimaryFlagClassOnFieldMarkedAsPrimary(fieldMarkedAsPrimaryContainer);
    this.handleComponentToggleVisibility(fieldMarkedAsPrimaryContainer);

    const controllerInstance = this;
    $(".js-marked-as-primary-radio-btn").each(function () {
      const isChecked = this === selectedRadioBtn;
      this.checked = isChecked;
      this.value = isChecked ? "true" : "false";

      $(this).toggleClass("bg-success border-success", isChecked);

      if (this === primarySpellingRadioBtn) {
        primaryTogglesContainer?.classList.toggle('d-none', !isChecked);
      } else {
        const otherValuesContainer = this.closest('.js-other-value-container');
        otherValuesContainer?.querySelector('.typing-field-btm-right-container')?.classList.toggle('d-none', !isChecked);
      }

      if (isChecked) {
        $(".js-text-field").removeClass("border-2 border-success");
        const textField = this.closest('.js-entries-input')?.querySelector('.js-text-field');
        textField?.classList.add("border-2", "border-success");
        controllerInstance.disableDragFromTextField(this);
      } else {
        controllerInstance.enableDragFromTextField(this);
      }
    });

  }
  
  handleComponentToggleVisibility(fieldMarkedAsPrimaryContainer){
    const previosPrimarySpelling = $('.js-entries-input.js-primary-spelling')[0];
    const ottomanTypingFields    = $('.js-entries-input');
    
    let afterPrimarySpelling = false;
    
    previosPrimarySpelling?.classList.remove('js-primary-spelling');
    fieldMarkedAsPrimaryContainer.closest('.js-entries-input').classList.add('js-primary-spelling');
    Array.from(ottomanTypingFields).forEach(spellingField => {
      
      // Remove component toggle of typing fields above primary spelling
      const componentToggle = spellingField.querySelector('.js-marked-as-component-toggle');
      
      if (componentToggle) {
        componentToggle.classList.add('d-none');
        componentToggle.querySelector('input.js-marked-as-component-toggle').checked = false;
        this.handleSpellingsVisibilityInLinkageModal(false);
      }

      if (spellingField.classList.contains('js-primary-spelling')) {
        afterPrimarySpelling = true;
        return;
      }

      if (afterPrimarySpelling) {
      // Add component toggle of typing fields below primary spelling
        spellingField.querySelector('.js-marked-as-component-toggle')?.classList.remove('d-none');
      }
    });
  }

  resetEntryWordSpellingsExistingLinkages(fieldMarkedAsPrimaryContainer){
    const formContainer          = fieldMarkedAsPrimaryContainer.closest('#typeo-fields-container');
    const spellingLinkagesModals = formContainer.querySelectorAll('.js-spelling-linkage-modal');
    
    spellingLinkagesModals.forEach(entryWordSpellingLinkage => {
      // Reset sources
      this.resetEntryWordLinkageSources(entryWordSpellingLinkage);
      
      // Reset Toggles
      this.resetEnrtyWordSpellingLinkageToggles(entryWordSpellingLinkage);
      
      //remove primary spelling flag from previous primary spellings fields
      this.removePrimaryFlagClassFromPreviousPrimarySpellingLinkages(entryWordSpellingLinkage);
    });
  }

  
  resetEntryWordLinkageSources(entryWordSpellingLinkage){
    const sourcesLinkages = entryWordSpellingLinkage.querySelectorAll('.js-latin-history-modal');
    sourcesLinkages.forEach(sourcesLinakge => {
      // Reset url fields
      const sourcesUrlsFields = sourcesLinakge.querySelectorAll('.js-source-url-input');
      sourcesUrlsFields.forEach(sourcesUrlField => {
        sourcesUrlField.value = '';
      });

      // Reset page no. fields
      const sourcesPageNoFields = sourcesLinakge.querySelectorAll('.js-source-page-no-input');
      sourcesPageNoFields.forEach(sourcesPageNoField => {
        sourcesPageNoField.value = '';
      });
    });
  }

  resetEnrtyWordSpellingLinkageToggles(entryWordSpellingLinkage, resetAlternateToggle = false){
    const relationshipToggles = entryWordSpellingLinkage.querySelectorAll('input[type="checkbox"]');
      
    relationshipToggles.forEach(relationshipToggle => {
      // Preserve the value of the alternative toggle
      if (resetAlternateToggle || !relationshipToggle.classList.contains('js-marked-as-alternate-value-toggle')) {
        relationshipToggle.checked = false;
      }
    });
  }

  removePrimaryFlagClassFromPreviousPrimarySpellingLinkages(entryWordSpellingLinkage){
    const currentWordPrimarySpellingContainers = entryWordSpellingLinkage.querySelectorAll('.js-current-word-primary-spelling');

    currentWordPrimarySpellingContainers.forEach(currentWordPrimarySpellingContainer => {
      if (currentWordPrimarySpellingContainer.classList.contains('js-primary-entry-linkage')) {
        currentWordPrimarySpellingContainer.classList.remove('js-primary-entry-linkage');
      }
    });
  }

  addPrimaryFlagClassOnFieldMarkedAsPrimary(fieldMarkedAsPrimaryContainer){
    const currentWordPrimarySpellingContainers = fieldMarkedAsPrimaryContainer.querySelectorAll('.js-current-word-primary-spelling');
    
    currentWordPrimarySpellingContainers.forEach(currentWordPrimarySpellingContainer => {
      currentWordPrimarySpellingContainer.classList.add('js-primary-entry-linkage');
    });
  }

  handleSpellingLinkagesIcon(selectedPrimarySpellingContainer) {
    const spellingLinkageIcon = selectedPrimarySpellingContainer.querySelector('.js-entry-words-primary-spellings-icon');
    
    // Early return in case linkage icon is not present
    if (spellingLinkageIcon == null) return;

    // Remove spelling linkage icon from all ottoman spellings fields 
    $('.js-entry-words-primary-spellings-icon').each(function() {
      if (!$(this).hasClass('collapse')) {
        $(this).addClass('collapse');
      }
    });

    // Display spelling linkage icon in selected primary ottoman spelling field
    spellingLinkageIcon.classList.remove('collapse');
  }

  resetComponentLinkagesHiddenFieldValues(spellingLinkageModal) {
    // Get the container for component spellings linkages
    const componentSpellingsLinkagesContainer = spellingLinkageModal.querySelector('.js-component-spelling-linkages-container');
    if (!componentSpellingsLinkagesContainer) return;

    // Get all component linkage elements inside the container
    const allComponentLinkages = componentSpellingsLinkagesContainer.querySelectorAll('.js-component-spelling-linkage');

    // Loop through each component linkage and reset the values
    allComponentLinkages.forEach(linkage => {
      const spellingRelationshipType = linkage.querySelector('.js-spelling-relationship-type');
      if (spellingRelationshipType) spellingRelationshipType.value = '';

      const explanationSpellingTempId = linkage.querySelector('.js-explanation-spelling-temp-id');
      if (explanationSpellingTempId) explanationSpellingTempId.value = '';

      const hiddenFieldForExplainedSpellingId = linkage.querySelector('.js-hidden-field-for-explained-spelling-id');
      if (hiddenFieldForExplainedSpellingId) hiddenFieldForExplainedSpellingId.value = '';

      const hiddenFieldForExplanationSpellingId = linkage.querySelector('.js-hidden-field-for-explanation-spelling-id');
      if (hiddenFieldForExplanationSpellingId) hiddenFieldForExplanationSpellingId.value = '';
    });
  }

  removeLinkagesOfSpellingUnmarkedAsComponent(linkagesToRemove){
    linkagesToRemove.forEach(linkage => {
      if (linkage.classList.contains('js-component-spelling-linkage-template')) {
        linkage.remove(); // Remove if it's a template
      } else {
        const destroyField = linkage.querySelector('.js-destroy-component-spelling-linkage');
        if (destroyField) {
          destroyField.value = "1"; // Mark to destroy
        }
      }
    });
  }

  resetComponentSpellingModalLinkages(spellingFieldContainer) {
    const spellingValue      = spellingFieldContainer.querySelector('.js-text-field').value;
    const allSpellings       = [...document.querySelectorAll('.js-entries-input')];

    // Filter component spellings from all spellings
    let componentSpellings   = typeo_form.getComponentSpellings(allSpellings);

    // Include current spelling in componentSpellings because its component toggle was just turned off in the frontend and its modal fields require reset.
    if (!componentSpellings.includes(spellingFieldContainer)) {
      componentSpellings.push(spellingFieldContainer);
    }

    componentSpellings.forEach(spelling => {
      const spellingLinkageModal = spelling.querySelector('.js-spelling-linkage-modal');
      if (spellingLinkageModal) {
        const componentSpellingLinkages = [...spellingLinkageModal.querySelectorAll('.js-component-spelling-linkage')];
        const linkagesToRemove   = componentSpellingLinkages.filter(linkage => {
          const explanationValue = linkage.querySelector('.js-component-spelling-explanation')?.textContent.trim();
          const explainedValue   = linkage.querySelector('.js-explained-component-spelling')?.textContent.trim();
          return explanationValue === spellingValue || explainedValue === spellingValue;
        }); 

        this.removeLinkagesOfSpellingUnmarkedAsComponent(linkagesToRemove);     
      }
    });
  }

  handleComponentPartToggleStateChange(event){
    const componentToggle = event.target;

    const spellingFieldContainer = componentToggle.closest('.js-entries-input');

    if (spellingFieldContainer) {
      // Find the `.js-entry-words-primary-spellings-icon` inside spellingFieldContainer
      const icon = spellingFieldContainer.querySelector('.js-entry-words-primary-spellings-icon');
      if (icon) {
        if (componentToggle.checked) {
          icon.classList.remove('collapse'); // Show the icon
        } else {
          this.resetComponentSpellingModalLinkages(spellingFieldContainer)
          icon.classList.add('collapse'); // Hide the icon
        }
      }
    }
  }

  handleSpellingsVisibilityInLinkageModal(isComponentChecked) {
    const typeoControllerElement = document.querySelector('[data-controller="typeo-prepopulation typeo-form"]');
    const typeoController = this.application.getControllerForElementAndIdentifier(typeoControllerElement, "typeo-form");

    //typeoController.handleMultiAndInPartWordSpellingsVisibiliyInLinkageModal(isComponentChecked);
    typeo_form.handleMultiAndInPartWordSpellingsVisibiliyInLinkageModal(isComponentChecked);
  }
}