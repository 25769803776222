import { jsPlumb } from "jsplumb";

import ApplicationController from "./application_controller"

const ARROW_CONNECTION_GAP = 15;

export default class extends ApplicationController {

  static targets = [
    'currentWordType', 
    'spellingLinkingCheckbox'
  ]

  initialize() {
    this.typing_for             = this.element.dataset['currentWordType'];
    this.isCurrentWordMultiWord = this.currentWordTypeTarget.dataset.isMultiCurrentWord === "true";
    this.isCurrentWordInParts   = this.currentWordTypeTarget.dataset.isInPartsCurrentWord === "true";
    this.isWordCategoryEntry    = this.element.dataset.isWordCategoryEntry === "true";

    this.removeComponentToggleFromTypingFieldsAbovePrimarySpelling();
    this.filterEntrySpellingsInLinkageModalBasedOnCurrentWordType();


    this.selectedSpellingCheckboxes = [];
    this.arrowConnections           = {};
    this.currentConnectionGaps      = { horizontalGap: 0, verticalGap: 0 };

    // This is the main container used for drawing and redrawing arrows for connections on scroll, along with other arrow-related functionalities.
    this.plumbContainer             = document.querySelector(".js-plumb-container");
    this.initializeJsPlumb();
    window['typeo_form'] = this;
  }

  removeComponentToggleFromTypingFieldsAbovePrimarySpelling(e) {
    const ottomanTypingFields = $('.js-entries-input');

    Array.from(ottomanTypingFields).some(spellingField => {
      spellingField.querySelector('.js-marked-as-component-toggle')?.classList.add('d-none');
      return spellingField.classList.contains('js-primary-spelling');
    });
  }

  destroyEntryCategorySpellingLinkagesOfPartToggleSpellingField(e) {
    const partToggle = e.currentTarget;
    const partToggleSpellingField = this.currentTypingFieldContainer(partToggle);
    const currentWordSpellingLinkagesFields = partToggleSpellingField.querySelectorAll('.js-spelling-linkages');

    if (partToggle.checked == true) {
      currentWordSpellingLinkagesFields.forEach(currentWordSpellingLinkagesField => {
        this.resetEnrtyCategoryWordSpellingLinkages(currentWordSpellingLinkagesField);
      });
    } else {
      currentWordSpellingLinkagesFields.forEach(currentWordSpellingLinkagesField => {
        if (!currentWordSpellingLinkagesField.classList.contains('collapse')){
          this.updateDestroyField(currentWordSpellingLinkagesField, false);
        }
      });
    }
  }
  
  // In case current word is multi this method 
  // - removes all in_parts words spellings from primary spelling linkage modal
  // when  current word is in parts this method 
  // - removes all multi words spellings from linkage modal
  filterEntrySpellingsInLinkageModalBasedOnCurrentWordType() {
    if (this.isCurrentWordMultiWord) {
      const primarySpellingField = $('.js-primary-spelling')[0];
      this.hideAllInPartsWordsSpellingFromLinkageModal(primarySpellingField);
    } else if (this.isCurrentWordInParts) {
      this.removeEntryMultiSpellingsFromLinkageModal();
    }
  }

  // this method removes all multi words spellings from linkage modal
  removeEntryMultiSpellingsFromLinkageModal() {
    const multiWordSpellingContainers = $('.js-entry-multi-spelling');

    Array.from(multiWordSpellingContainers).some(multiWordSpellingContainer => {
      multiWordSpellingContainer.classList.add('collapse');
      this.resetEnrtyCategoryWordSpellingLinkages(multiWordSpellingContainer);
    });
  }

  // This method populates current spelling value in linkages modal
  populateCurrentSpellingInLinkageModal(e){
    const spellingRelationIcon   = e.currentTarget;
    
    const spellingContainer      = this.currentTypingFieldContainer(spellingRelationIcon);
    const spellingIsComponent    = this.isComponentSpelling(spellingContainer);

    const currentPrimarySpelling = spellingContainer.querySelector('.js-spelling-field').value;
    const primarySpellingsFields = spellingContainer.querySelectorAll('.js-current-word-primary-spelling');

    primarySpellingsFields.forEach(spellingField => {
      spellingField.textContent = currentPrimarySpelling;
      if (spellingIsComponent) {
        const spellingsLinkageFieldsContainer = spellingField.closest('.js-entries-input');
        const unrelatedToggleContainer        = spellingsLinkageFieldsContainer?.querySelector('.js-unrelated-toggle-container')
        unrelatedToggleContainer?.classList.remove('d-none');
      }
    });
  }

  // This method is triggered when molecule icon is clicked
  // And handles visibility of in_parts entry category words spellings in linkage modal ofprimary spelling in case of current combined word
  handleVisibilityOfInPartEntrySpellingsInCombinedWordPrimarySpellingLinkageModal(e) {
    if (this.typing_for != 'combined_word') return;

    const spellingRelationIcon   = e.currentTarget;
    const spellingContainer      = this.currentTypingFieldContainer(spellingRelationIcon);
    const currentPrimarySpelling = spellingContainer.querySelector('.js-spelling-field').value;
    const isFirstSpellingField   = spellingContainer.classList.contains('js-first-spelling-field');
    const isPrimarySpelling      = spellingContainer.classList.contains('js-primary-spelling');
    const isMultiWord            = isFirstSpellingField && isPrimarySpelling && currentPrimarySpelling.trim().includes(' ')
    
    // In case current spelling field is primary and also first spelling field and has space in ottoman spelling
    // hide all in_parts words spellings
    if (isMultiWord) {
      this.hideAllInPartsWordsSpellingFromLinkageModal(spellingContainer);

    } else if(isPrimarySpelling) {
      // In case current spelling field is primary field but not the first field
      // check if components toggles are checked if not then display in part spellings
      const checkedComponentToggles = $('input.js-marked-as-component-toggle:checked');
      
      if (checkedComponentToggles.length > 0) return ;

     this.displayAllInPartSpellingsFromLinkageModal(spellingContainer);
    }
  }
  
  isComponentSpelling(spelling){
    const componentToggle = spelling.querySelector('.js-marked-as-component-checkbox');
    return componentToggle?.checked || false;    
  }

  getComponentSpellings(spellings) {
    // Filter only those spellings where the checkbox is checked
    const componentSpellings = spellings.filter(spelling => {
      const componentToggle = spelling.querySelector('.js-marked-as-component-checkbox');
      return componentToggle?.checked || false;
    });

    return componentSpellings;    
  }

  componentSpellingPairsToBePopulated(currentSpelling, componentSpellings) {
    // Step 1: Create pairs of currentSpelling with each componentSpelling
    const allPairs = componentSpellings.map(spelling => ({
      componentSpellingExplanation: spelling,
      currentComponentSpelling: currentSpelling
    }));

    // Step 2: Get all linked component spellings, meaning the spellings that have already been saved and are present in the modal.
    const linkedComponentElements = document.querySelectorAll('.js-component-spelling-linkage');

    // Step 3: Extract pairs from existing linked component spellings
    const existingLinkedPairs = [...linkedComponentElements].map(element => ({
      componentSpellingExplanation: element.querySelector('.js-component-spelling-explanation'),
      explainedComponentSpelling: element.querySelector('.js-explained-component-spelling')
    }));
    
    // Step 4: Filter out pairs that already exist in existingLinkedPairs to get the pairs that need to be populated in the modal.
    const componentSpellingPairsToPopulate = allPairs.filter(pair => {
      return !existingLinkedPairs.some(existingPair => {
        return existingPair.componentSpellingExplanation.textContent.trim() === pair.componentSpellingExplanation.querySelector('.js-text-field').value &&
               existingPair.explainedComponentSpelling.textContent.trim() === pair.currentComponentSpelling.querySelector('.js-text-field').value;
      });
    });
    
    return componentSpellingPairsToPopulate;
  }

  populateValuesInComponentSpellingTemplateFields(componentSpellingsLinkageContainer, spellingsToLink) {
    const explanationValue          = spellingsToLink.componentSpellingExplanation.querySelector('.js-text-field')?.value || "";
    const explanationSpellingTempId = spellingsToLink.componentSpellingExplanation.querySelector('.js-spelling-temp-reference-id')?.value || "";    
    const explainedValue            = spellingsToLink.currentComponentSpelling.querySelector('.js-text-field')?.value || "";

    const explanationValueDiv            = componentSpellingsLinkageContainer.querySelector('.js-component-spelling-explanation');
    const explainedValueDiv              = componentSpellingsLinkageContainer.querySelector('.js-explained-component-spelling');
    const explanationSpellingTempIdField = componentSpellingsLinkageContainer.querySelector('.js-explanation-spelling-temp-id');

    if (explanationValueDiv) {
      explanationValueDiv.textContent = explanationValue;
    }

    if (explainedValueDiv) {
      explainedValueDiv.textContent = explainedValue;
    }

    if (explanationSpellingTempIdField) {
      explanationSpellingTempIdField.value = explanationSpellingTempId;
    }    
  }

  populateComponentSpellingLinakgesFields(componentSpellingPairsToPopulate, currentSpelling) {
    const currentSpellingLinkageModal = this.spellingLinkageModal(currentSpelling);
    const buttonToAddTemplate         = currentSpellingLinkageModal.querySelector('.js-add-component-spelling-linkage-template');

    if (!buttonToAddTemplate) return;

    const targetContainer = buttonToAddTemplate.closest('.js-component-spelling-linkages-container')
      ?.querySelector('.js-component-spelling-linkages-template-container');

    if (!targetContainer) return;

    // A delay is added after clicking the button to add a new template 
    // because without this delay, attempting to access the newly added template immediately resulted in selecting non-existent element
    const populatePairOfSpellingsInModalFields = (pair) => {
      return new Promise((resolve) => {
        buttonToAddTemplate.click();

        setTimeout(() => {
          const newTemplateContainer = targetContainer.children[targetContainer.children.length - 1];

          if (newTemplateContainer) {
            this.populateValuesInComponentSpellingTemplateFields(newTemplateContainer, pair);
          }

          resolve();
        }, 100);
      });
    };

    componentSpellingPairsToPopulate.reduce((promiseChain, pair) => {
      return promiseChain.then(() => populatePairOfSpellingsInModalFields(pair));
    }, Promise.resolve());
  }

  spellingLinkageModal(spelling){
    const spellingLinkageModal = spelling.querySelector('.js-spelling-linkage-modal');
    return spellingLinkageModal;
  }

  unhideEntryWordPrimarySpellingLinkageContainer(currentSpelling) {
    const currentModal = currentSpelling.querySelector('.js-spelling-linkage-modal');
    const entryWordPrimarySpellingLinkageContainer = currentModal.querySelector('.js-entry-words-primary-seplling-linkage-container');
    
    if (entryWordPrimarySpellingLinkageContainer) {
      entryWordPrimarySpellingLinkageContainer.classList.remove('d-none');
    }
  }

  // This method removes multi-word spellings and
  // displays in-part-word spellings in the current spelling linkage modal.
  handleEntrySpellingsLinkagesVisibilityOfComponentSpelling(currentSpelling) {
    const currentSpellingLinkageModal = this.spellingLinkageModal(currentSpelling);
    
    if (!currentSpellingLinkageModal) return;
    
    const entryWordPrimarySpellingLinkageContainer = currentSpellingLinkageModal.querySelector('.js-entry-words-primary-seplling-linkage-container');
    
    if (entryWordPrimarySpellingLinkageContainer) {
      // collapse multi word spellings 
      this.hideMultiWordsSpellingsFromLinkageModal(entryWordPrimarySpellingLinkageContainer);

      // collapse curent word in_parts word spellings 
      this.hideCurrentWordInPartsWordsSpellingsFromLinkageModal(entryWordPrimarySpellingLinkageContainer);
      
      // display in part words spellings
      this.displayOtherEntryCategoryInPartsWordsSpellingsFromLinkageModal(entryWordPrimarySpellingLinkageContainer);
    }
  }

  // This method populates the fields in the molecule modal for component spellings using template
  populateComponentSpellingLinkagesInLinkagesModal(e) {
    const spellingLinkageIcon = e.target;
    const currentSpelling     = spellingLinkageIcon.closest('.js-entries-input');
    // Will test it after conflict resolved
    if (!this.isComponentSpelling(currentSpelling)) {
      this.unhideEntryWordPrimarySpellingLinkageContainer(currentSpelling);
      return; // Exit early if spelling is not a component spelling
    }

    // remove multi word spellings and display in part word spellings in linkage modal of component spelling
    this.handleEntrySpellingsLinkagesVisibilityOfComponentSpelling(currentSpelling);

    // Get all spellings except the current spelling
    const allSpellings = [...document.querySelectorAll('.js-entries-input')].filter(spelling => spelling !== currentSpelling);

    // Get all component spellings except the current spelling
    const componentSpellings = this.getComponentSpellings(allSpellings);

    const componentSpellingPairsToPopulate = this.componentSpellingPairsToBePopulated(currentSpelling, componentSpellings)

    // If there are unpopulated component spellings to be added to the modal, populate them in the modal
    if (componentSpellingPairsToPopulate.length > 0) {
      this.populateComponentSpellingLinakgesFields(componentSpellingPairsToPopulate, currentSpelling);
    }    
  }

  openEntryWordLinkageSourcesModal(e) {
    e.preventDefault();

    // Get the modal ID from the button's data-bs-target
    let modalId = e.currentTarget.getAttribute("data-bs-target");
    let modalElement = document.querySelector(modalId);

    this.handleSourcesModalPosition(e, modalElement);

    // Open the second modal manually
    const sourcesModal = new bootstrap.Modal(modalElement)
    modalElement.querySelector('.modal-content').classList.add("spelling-linkage-sources-content")
    sourcesModal.show();
  }

  handleSourcesModalPosition(e, sourcesModalElement){
    let sourcesModalDialog = sourcesModalElement.querySelector('.modal-dialog');

    // Get sources '+' button position
    const sourcesButtonRect = e.currentTarget.getBoundingClientRect();
    
    // Position modal-dialog near the button
    sourcesModalDialog.style.position = "absolute";
    
    // check if source is last visible on screen 
    if (window.innerHeight - sourcesButtonRect.bottom < 200) {
      sourcesModalDialog.style.top = `${sourcesButtonRect.top - 350}px`;
    } else {
      sourcesModalDialog.style.top = `${sourcesButtonRect.bottom - 40}px`;
    }

    sourcesModalDialog.style.left = `${sourcesButtonRect.left - 650}px`;
  }

  handleSpellingsVisibilityInLinkageModals(e) {
    const componentPartToggle = e.target;
    const isComponentToggleChecked = componentPartToggle.checked;
    this.handleMultiAndInPartWordSpellingsVisibiliyInLinkageModal(isComponentToggleChecked);
  }

  handleMultiAndInPartWordSpellingsVisibiliyInLinkageModal(isComponentToggleChecked) {
    const checkedComponentToggles = $('input.js-marked-as-component-toggle:checked');
    
    // show in parts/multi words spelling if user unchecked all components toggles
    // hide in parts/multi words spelling if user checked first component toggle
    if ( checkedComponentToggles.length == 0 && isComponentToggleChecked == false || 
         checkedComponentToggles.length == 1 && isComponentToggleChecked == true
        ) {

      if (this.isCurrentWordInParts) {
        this.handleVisbilityOfMultiEntryWordsInLinkageModal(isComponentToggleChecked);
      }

      this.handleVisbilityOfInPartEntryWordsSpellingLinkageModal(isComponentToggleChecked);
    }
  }

  // this method handles visibility of all multi words spellings in linkages modal based on component toggle stage
  handleVisbilityOfMultiEntryWordsInLinkageModal(isComponentToggleChecked){
    const multiEntrySpellingContainers = $('.js-entry-multi-spelling');

    Array.from(multiEntrySpellingContainers).some(multiEntrySpellingContainer => {
      
      if (isComponentToggleChecked) {
        multiEntrySpellingContainer.classList.remove('collapse');
        this.updateDestroyField(multiEntrySpellingContainer, false)

      } else {
        multiEntrySpellingContainer.classList.add('collapse');
        this.resetEnrtyCategoryWordSpellingLinkages(multiEntrySpellingContainer);
      }
    });
  }

 // this method handles visibility of all in_parts words spellings in linkages modal based on component toggle stage
  handleVisbilityOfInPartEntryWordsSpellingLinkageModal(isComponentToggleChecked){
    this.handleVisibilityOfInPartWordsInPrimarySpellingLinkages(isComponentToggleChecked);
    this.handleVisibilityOfInPartWordsInComponentSpellingLinkages(isComponentToggleChecked);
  }
 
  // this method handles visibility of all in_parts words spellings in primary spelling linkages modal based on component toggle stage
  // in case component toggle is on hide ann in_parts words spellings from primary speling linkages modal
  // for toggle off we have two cases one for individual word one for combined word
  // In case of individual word if all component toggles are off display  all in_parts words spellings
  // In case of combined word 
  //  - If all component toggles are off check for multi word based on space in first primary spelling
  //  - if combined word is multi word (based on first primary spelling space) then hide all in_parts words spellings
  //  - if combined word is not multi word then display all in_parts words spellings
  handleVisibilityOfInPartWordsInPrimarySpellingLinkages(isComponentToggleChecked){
    const primarySpellingField = $('.js-primary-spelling')[0];

    if (isComponentToggleChecked) {
      this.hideAllInPartsWordsSpellingFromLinkageModal(primarySpellingField)
    } else {
      if (this.typing_for == 'combined_word'){
        const primarySpellingValue = primarySpellingField.querySelector('.js-spelling-field').value;
        const isFirstPrimaryField = primarySpellingField.classList.contains('js-first-spelling-field');
        const isMultiWord = isFirstPrimaryField && primarySpellingValue.trim().includes(' ');
        
        if (!isMultiWord) {
          this.displayAllInPartSpellingsFromLinkageModal(primarySpellingField);
        }
      } else {
        this.displayAllInPartSpellingsFromLinkageModal(primarySpellingField);
      }
    }
  }

  // this method handles visibility of all in_parts words spellings in component spelling linkages modals based on component toggle stage
  // if component toggle is on then hide current words in_parts words spellings and also hide multi words spellings
  // if component toggle is off then reset all  toggles of component modals 
  handleVisibilityOfInPartWordsInComponentSpellingLinkages(isComponentToggleChecked){
    const spellingLinkageModals = $('.js-spelling-linkage-modal');

    Array.from(spellingLinkageModals).some(spellingLinkageModal => {
      const spellingContainer = this.currentTypingFieldContainer(spellingLinkageModal);
      // return in case of primary spelling spelling modal
      if (spellingContainer.classList.contains('js-primary-spelling')) return false;
      
      if (isComponentToggleChecked){

        this.hideCurrentWordInPartsWordsSpellingsFromLinkageModal(spellingLinkageModal);
        this.hideMultiWordsSpellingsFromLinkageModal(spellingLinkageModal);
      } else {
        // reset all spellings 
        this.resetEnrtyCategoryWordSpellingLinkages(spellingLinkageModal);
      }
    });
  }

  // this method hides in_part spellings in current spelling field linkages modal
  hideAllInPartsWordsSpellingFromLinkageModal(spellingField) {
    this.hideCurrentWordInPartsWordsSpellingsFromLinkageModal(spellingField);
    this.hideOtherEntryCategoryInPartsWordsSpellingsFromLinkageModal(spellingField)
  }

  // this method hides current word in_parts words spellings
  hideCurrentWordInPartsWordsSpellingsFromLinkageModal(spellingField) {
    const currentWordInPartEntrySpellingContainers = spellingField.querySelectorAll('.js-current-word-part-spelling');

    // hide current word in_parts words spellings
    currentWordInPartEntrySpellingContainers.forEach(currentWordInPartEntrySpellingContainer => {
      currentWordInPartEntrySpellingContainer.classList.add('collapse');
      this.resetEnrtyCategoryWordSpellingLinkages(currentWordInPartEntrySpellingContainer);
    });
  }

  // this method hides in_parts words spellings which are not in_parts words of current word 
  hideOtherEntryCategoryInPartsWordsSpellingsFromLinkageModal(spellingField) {
    const inPartEntrySpellingContainers = spellingField.querySelectorAll('.js-entry-part-spelling');
    
    // hide all other in_parts words spellings that are not in_parts words of current word 
    inPartEntrySpellingContainers.forEach(inPartEntrySpellingContainer => {
      inPartEntrySpellingContainer.classList.add('collapse');
      this.resetEnrtyCategoryWordSpellingLinkages(inPartEntrySpellingContainer);
    });
  }

  // this method hides multi words spellings 
  hideMultiWordsSpellingsFromLinkageModal(spellingField) {
    if (!this.isWordCategoryEntry) return; // Early return if word type is not Entry

    const multiWordContainers = spellingField.querySelectorAll('.js-entry-multi-spelling');
        
    multiWordContainers.forEach(multiWordContainer => {
      multiWordContainer.classList.add('collapse');
      this.resetEnrtyCategoryWordSpellingLinkages(multiWordContainer);
    });
  }

  // this method displays in_part spellings in current spelling field linkages modal
  displayAllInPartSpellingsFromLinkageModal(spellingField) {
    this.displayCurrentWordInPartsWordsSpellingsFromLinkageModal(spellingField);
    this.displayOtherEntryCategoryInPartsWordsSpellingsFromLinkageModal(spellingField);
  }

  // this method displays current word in_parts words spellings
  displayCurrentWordInPartsWordsSpellingsFromLinkageModal(spellingField) {
    const currentWordInPartEntrySpellingContainers = spellingField.querySelectorAll('.js-current-word-part-spelling');

    // display current word in_parts words spellings
    currentWordInPartEntrySpellingContainers.forEach(currentWordInPartEntrySpellingContainer => {
      currentWordInPartEntrySpellingContainer.classList.remove('collapse');
      this.updateDestroyField(currentWordInPartEntrySpellingContainer, false)
    });
  }

  // this method displays in_parts words  spellings which are not in_parts words of current word 
  displayOtherEntryCategoryInPartsWordsSpellingsFromLinkageModal(spellingField) {
    const inPartEntrySpellingContainers = spellingField.querySelectorAll('.js-entry-part-spelling');
    
    // display all other in_parts words spellings that are not in_parts words of current word 
    inPartEntrySpellingContainers.forEach(inPartEntrySpellingContainer => {
      inPartEntrySpellingContainer.classList.remove('collapse');
      this.updateDestroyField(inPartEntrySpellingContainer, false)
    });
  }


  resetEnrtyCategoryWordSpellingLinkages(spellingField){
    // Reset sources
    this.resetEntryWordLinkageSources(spellingField);
    
    // Reset Toggles
    this.resetEnrtyWordSpellingLinkageToggles(spellingField);

    // Mark destroy hidden field to true
    this.updateDestroyField(spellingField, true);
  }

  
  resetEntryWordLinkageSources(entryWordSpellingLinkage){
    const sourcesLinkages = entryWordSpellingLinkage.querySelectorAll('.js-latin-history-modal');
    sourcesLinkages.forEach(sourcesLinakge => {
      // Reset url fields
      const sourcesUrlsFields = sourcesLinakge.querySelectorAll('.js-source-url-input');
      sourcesUrlsFields.forEach(sourcesUrlField => {
        sourcesUrlField.value = '';
      });

      // Reset page no. fields
      const sourcesPageNoFields = sourcesLinakge.querySelectorAll('.js-source-page-no-input');
      sourcesPageNoFields.forEach(sourcesPageNoField => {
        sourcesPageNoField.value = '';
      });
    });
  }

  resetEnrtyWordSpellingLinkageToggles(entryWordSpellingLinkage, resetAlternateToggle = false){
    const relationshipToggles = entryWordSpellingLinkage.querySelectorAll('input[type="checkbox"]');
      
    relationshipToggles.forEach(relationshipToggle => {
      // Preserve the value of the alternative toggle
      if (resetAlternateToggle || !(relationshipToggle.classList.contains('js-marked-as-alternate-value-toggle') && relationshipToggle.disabled == true)) {
        relationshipToggle.checked = false;
      }
    });
  }

  updateDestroyField(spellingField, markAsDestroyed) {
    const hiddenDestroyField = spellingField?.querySelector('.js-destroy-component-spelling-linkage');
    
    if(hiddenDestroyField){
      hiddenDestroyField.value = markAsDestroyed;
    }
  }

  // ************ Code to make linkages between spellings through arrows STARTS here ************ //

  // This method initializes Plumb
  initializeJsPlumb() {
    jsPlumb.ready(() => {
      // const container = document.querySelector(".js-plumb-container");
      if (this.plumbContainer) {
        this.jsPlumbInstance = jsPlumb.getInstance({
          Container: this.plumbContainer
        });

        // This method draws the arrows for linkages when an item is navigated, it checks all linkages and draws arrows only for those that are not immediately above or below the field.
        this.drawPersistedConnections();
        
        // Handle click/right-click events to remove connections. Arrow connection is removed by clicking/right-clicking the Arrow 
        this.jsPlumbInstance.bind("click", this.removeArrowConnection.bind(this));
        this.jsPlumbInstance.bind("contextmenu", this.removeArrowConnection.bind(this));  // to handle right click case     
      } else {
        console.error("jsPlumb container not found!");
      }
    });
  }

  // This method refreshes the JsPlumb instance by:
  // - Revalidating the container element to update JsPlumb's internal state.
  // - Recalculating offsets to ensure accurate positioning of connections.
  // - Repainting all connections and elements to reflect any changes.  
  refreshJsPlumb() {
    // Revalidate the container element to refresh jsPlumb's internal state
    this.jsPlumbInstance.revalidate(this.plumbContainer);
    this.jsPlumbInstance.recalculateOffsets();
    this.jsPlumbInstance.repaintEverything();
  }

  resetConnectionGaps(){
    this.currentConnectionGaps = { horizontalGap: 0, verticalGap: 0 };
  }

  decreaseConnectionArrowSpacing() {
    // Decrease horizontal and vertical gaps by 15, ensuring they don't go below zero
    this.currentConnectionGaps.horizontalGap = Math.max(0, this.currentConnectionGaps.horizontalGap - ARROW_CONNECTION_GAP);
    this.currentConnectionGaps.verticalGap = Math.max(0, this.currentConnectionGaps.verticalGap - ARROW_CONNECTION_GAP);
  }

  removeArrowConnection(connection, event = null) {
    if (event) {
      event.preventDefault(); // To prevent default action on right-click
    }

    // Get the connection checkboxes
    const sourceCheckbox = connection.source;
    const targetCheckbox = connection.target;

    const sourceId       = sourceCheckbox.getAttribute('data-spelling-linkage-checkbox');

    this.jsPlumbInstance.deleteConnection(connection);
    if (this.arrowConnections[sourceId]) {
        delete this.arrowConnections[sourceId];
    }

    this.resetExplanationSpellingTempId(sourceCheckbox);
    this.enableDragForDisconnectedFields(sourceCheckbox, targetCheckbox)
    this.decreaseConnectionArrowSpacing();
  }  

  // Remove all connections but keep the source id in this.connections to allow redrawing of connections
  removeExistingArrowConnections() {
    this.jsPlumbInstance.deleteEveryConnection();
    this.spellingLinkingCheckboxTargets.forEach(checkbox => {
      this.resetExplanationSpellingTempId(checkbox);
    });
  } 

  removeAllConnectionsRelatedToField(event) {
    const spellingField = event.target.closest('.js-entries-input');
    if (!spellingField) return;

    const spellingFieldCheckbox = spellingField.querySelector('.js-spelling-linkage-checkbox');
    if (!spellingFieldCheckbox) return;

    const checkboxId = spellingFieldCheckbox.getAttribute('data-spelling-linkage-checkbox');
    if (!checkboxId) return;

    // Get all connections where this element is either a source or a target
    const connectionsAsSource = this.jsPlumbInstance.getConnections({ source: spellingFieldCheckbox });
    const connectionsAsTarget = this.jsPlumbInstance.getConnections({ target: spellingFieldCheckbox });

    // Combine both sets of connections
    const deletedFieldAllConnections = [...connectionsAsSource, ...connectionsAsTarget];

    // Early return if there are no connections
    if (deletedFieldAllConnections.length === 0) return;

    // Remove each connection
    deletedFieldAllConnections.forEach(connection => {
      this.removeArrowConnection(connection); // Manually trigger removal   
    });

    // this.redrawFieldConnections();
  } 

  // This method should be called on actions which reorder a field, such as reordering fields through drag handle, adding a new field between existing ones, or deleting a field.
  redrawFieldConnections() {
    this.removeExistingArrowConnections();
    this.resetConnectionGaps();
    this.unhideAllLinkageCheckboxes();
    this.refreshJsPlumb();

    const spellingOrderNumbers = this.getSortedSpellingOrderNumbers();

    Object.entries(this.arrowConnections).forEach(([sourceId, targetId]) => {
      const source = document.querySelector(`[data-spelling-linkage-checkbox="${sourceId}"]`);
      const target = document.querySelector(`[data-spelling-linkage-checkbox="${targetId}"]`);

      if (source && target) {
        const sourceOrderNumber = this.checkboxFieldOrderNumber(source);
        const targetOrderNumber = this.checkboxFieldOrderNumber(target);

        const currentIndex = spellingOrderNumbers.indexOf(sourceOrderNumber);
        const immediatePrevious = currentIndex > 0 ? spellingOrderNumbers[currentIndex - 1] : null;
        const immediateNext = currentIndex < spellingOrderNumbers.length - 1 ? null : spellingOrderNumbers[currentIndex + 1];

        // Check if the connection is for the immediate above or below field,
        // or if the explanation field is positioned below the explained field.
        // If any of these conditions are met, skip the connection creation process.
        if (targetOrderNumber === immediatePrevious || targetOrderNumber === immediateNext || targetOrderNumber >= sourceOrderNumber) {
          this.enableDragForDisconnectedFields(source, target)

          if (this.arrowConnections[sourceId]) {
              delete this.arrowConnections[sourceId];
          }
          
          return;
        }

        this.createJsPlumbConnection(source, target);
      } else {
        console.error("Source or target element not found:", { sourceId, targetId });
      }
    });

    this.jsPlumbInstance.repaintEverything();
  }

  // Each spelling field has a hidden field storing the temp ID of its explanation spelling.  
  // This method retrieves the explanation spelling field container using the temp ID  
  // and then gets the explanation field's order number.
  getExplanationSpellingOrderNumber(spelling) {
    const explanationSpellingTemporaryId = spelling.querySelector('.js-explanation-spelling-temp-reference-id')?.value || null;
    if (!explanationSpellingTemporaryId) return null;
    
    const spellings = [...document.querySelectorAll('.js-entries-input')];
    for (const spellingItem of spellings) {
      const spellingTempId = spellingItem.querySelector('.js-spelling-temp-reference-id')?.value;
      if (spellingTempId === explanationSpellingTemporaryId) {
        return spellingItem.querySelector('.js-otto-spelling-assigned-number')?.value || null;
      }
    }
    
    return null;
  }

  // Helper method for a repeated functionality.  
  getSortedSpellingOrderNumbers() {
    const spellings = [...document.querySelectorAll('.js-entries-input')];

    // Step 2: Filter out hidden spellings that are marked for deletion
    const visibleSpellings = spellings.filter(spelling => {
      const wrapper = spelling.closest('.js-nested-form-wrapper');
      return !(wrapper && getComputedStyle(wrapper).display === "none"); // Keep only visible spellings
    });

    // Step 3: Extract order numbers from visible spellings
    const orderNumbers = visibleSpellings.map(spelling => {
      const orderField = spelling.querySelector('.js-otto-spelling-assigned-number');
      return orderField ? parseInt(orderField.value, 10) : null;
    });

    // Remove null values
    const validNumbers = orderNumbers.filter(num => num !== null);

    // Sort numbers in ascending order
    return validNumbers.sort((a, b) => a - b);   
  }

  // This method is called when an item is navigated from initialize method
  // To draw the arrows for linkages when an item is navigated, it checks all linkages and draws arrows only for those that are not immediately above or below the field.
  drawPersistedConnections() {
    this.currentConnectionGaps = { horizontalGap: 0, verticalGap: 0 };
    const spellingOrderNumbers = this.getSortedSpellingOrderNumbers();
    const spellings = [...document.querySelectorAll('.js-entries-input')]; // Converting to an array for easy iteration and tracking
    spellings.forEach(spelling => {
      const hiddenFieldForCurrentSpellingOrderNumber = spelling.querySelector('.js-otto-spelling-assigned-number');
      const explanationSpellingOrderNumber           = this.getExplanationSpellingOrderNumber(spelling);

      if (hiddenFieldForCurrentSpellingOrderNumber && explanationSpellingOrderNumber !== "") {
        const currentSpellingOrderNumber             = parseInt(hiddenFieldForCurrentSpellingOrderNumber.value, 10);
        const requiredExplanationSpellingOrderNumber = parseInt(explanationSpellingOrderNumber, 10);

        // Find the immediate previous and next spelling order numbers
        const currentIndex      = spellingOrderNumbers.indexOf(currentSpellingOrderNumber);
        const immediatePrevious = currentIndex > 0 ? spellingOrderNumbers[currentIndex - 1] : null;
        const immediateNext     = currentIndex < spellingOrderNumbers.length - 1 ? spellingOrderNumbers[currentIndex + 1] : null;

        // Skip if the required explanation spelling order number is the immediate previous or next
        if (requiredExplanationSpellingOrderNumber === immediatePrevious || requiredExplanationSpellingOrderNumber === immediateNext) {
          return; // Skip this spelling
        }

        // Find the spelling field where the required explanation value matches
        const explanationSpellingField = spellings.find(explanationSpelling => {
          const explanationFieldOrderNumber = explanationSpelling.querySelector('.js-otto-spelling-assigned-number');
          return explanationFieldOrderNumber && parseInt(explanationFieldOrderNumber.value, 10) === requiredExplanationSpellingOrderNumber;
        });

        if (explanationSpellingField) {
          // Get checkboxes inside both spellings(the explained and the explanation)
          const explainedCheckbox = spelling.querySelector('.js-spelling-linkage-checkbox');
          const explanationCheckbox = explanationSpellingField.querySelector('.js-spelling-linkage-checkbox');

          if (explainedCheckbox && explanationCheckbox) {
            this.createJsPlumbConnection(explainedCheckbox, explanationCheckbox);
          }
        }
      }
    });
  }

  // This method returns the order number of the spelling field associated with the checkbox.
  checkboxFieldOrderNumber(checkBox) {
    const checkBoxFieldContainer = checkBox.closest('.js-entries-input');
    if (!checkBoxFieldContainer) return null;

    const orderNumber = checkBoxFieldContainer.querySelector('.js-otto-spelling-assigned-number');
    return orderNumber ? parseInt(orderNumber.value, 10) : null;    
  } 

  // This method is called when a checkbox is checked/selected.
  // Method to keep the track of selected checkboxes - this method ensures that at most two checkboxes can be selected at a time, if a third checkbox is selected, the earliest selected one is then unchecked
  handleSpellingLinkageCheckboxStateChange(event) {
    const checkbox          = event.currentTarget;
    const linkageCheckboxId = checkbox.dataset.spellingLinkageCheckbox;

    if (checkbox.checked) {
      this.selectedSpellingCheckboxes.push(linkageCheckboxId);
    } else {
      this.selectedSpellingCheckboxes = this.selectedSpellingCheckboxes.filter(id => id !== linkageCheckboxId);
    }

    // Keep only two selections
    if (this.selectedSpellingCheckboxes.length > 2) {
      const removedCheckBox   = this.selectedSpellingCheckboxes.shift();
      const checkBoxToUncheck = this.spellingLinkingCheckboxTargets.find(cb => cb.dataset.spellingLinkageCheckbox === removedCheckBox);
      if (checkBoxToUncheck) checkBoxToUncheck.checked = false;
    }
  }  

  // This method resets the Explanation Spelling Order Number in the hidden field when a connected is removed, either by clicking an arrow or through code on certain actions (it resets the hidden field value having the explanaion spelling temp id)
  resetExplanationSpellingTempId(explainedSpellingCheckBox) {
    const explainedSpellingFieldContainer = explainedSpellingCheckBox.closest('.js-entries-input');
    
    if (explainedSpellingFieldContainer) {
      const hiddenFieldForExplanationSpellingTempId = explainedSpellingFieldContainer.querySelector('.js-explanation-spelling-temp-reference-id');
      if (hiddenFieldForExplanationSpellingTempId) {
        hiddenFieldForExplanationSpellingTempId.value = ''; // Reset value to empty string
      }
    }
  } 

  // Sets the value of explanation_spelling_temp_id in the source spelling(explained spelling) fields
  setExplanationSpellingTempId(explainedSpellingCheckBox, explanationSpellingCheckBox) {
    const explanationSpellingFieldContainer = explanationSpellingCheckBox.closest('.js-entries-input');
    if (explanationSpellingFieldContainer) {
      const explanationSpellingTempId = explanationSpellingFieldContainer.querySelector('.js-spelling-temp-reference-id')?.value;

      // Set the explanation_spelling_temp_id value in explainedSpellingCheckBox's associated input
      const explainedSpellingFieldContainer = explainedSpellingCheckBox.closest('.js-entries-input');
      if (explainedSpellingFieldContainer && explanationSpellingTempId) {
        const hiddenFieldForExplanationSpellingTempId = explainedSpellingFieldContainer.querySelector('.js-explanation-spelling-temp-reference-id');
        
        if (hiddenFieldForExplanationSpellingTempId) {
          hiddenFieldForExplanationSpellingTempId.value = explanationSpellingTempId;
        }
      }
    }
  }

  enableDragForDisconnectedFields(explainedSpellingCheckBox, explanationSpellingCheckBox) {
    [explainedSpellingCheckBox, explanationSpellingCheckBox].forEach(checkbox => {
      const spellingFieldContainer = checkbox.closest('.js-entries-input');
      if (spellingFieldContainer) {
        const dragHandle = spellingFieldContainer.querySelector('.js-drag-handle');
        if (dragHandle) {
          dragHandle.classList.remove('d-none');
        }
      }
    });    
  }

  disableDragForArrowConnectedFields(explainedSpellingCheckBox, explanationSpellingCheckBox) {
    [explainedSpellingCheckBox, explanationSpellingCheckBox].forEach(checkbox => {
      const spellingFieldContainer = checkbox.closest('.js-entries-input');
      if (spellingFieldContainer) {
        const dragHandle = spellingFieldContainer.querySelector('.js-drag-handle');
        if (dragHandle) {
          dragHandle.classList.add('d-none');
        }
      }
    });    
  }

  // This method is called when the Enter key is pressed after selecting checkboxes on two fields
  handleSpellingsLinkingThroughArrows() {
    event.preventDefault();
    this.redrawFieldConnections();

    const [firstCheckbox, secondCheckbox] = this.selectedSpellingCheckboxes;

    const firstCheckboxElement  = this.spellingLinkingCheckboxTargets.find(cb => cb.dataset.spellingLinkageCheckbox === firstCheckbox);
    const secondCheckboxElement = this.spellingLinkingCheckboxTargets.find(cb => cb.dataset.spellingLinkageCheckbox === secondCheckbox);

    const firstCheckboxOrderNum  = this.checkboxFieldOrderNumber(firstCheckboxElement)
    const secondCheckboxOrderNum = this.checkboxFieldOrderNumber(secondCheckboxElement)

    let explainedSpellingCheckBox, explanationSpellingCheckBox;

    if (firstCheckboxOrderNum > secondCheckboxOrderNum) {
      explainedSpellingCheckBox   = firstCheckboxElement;
      explanationSpellingCheckBox = secondCheckboxElement;
    } else {
      explainedSpellingCheckBox   = secondCheckboxElement;
      explanationSpellingCheckBox = firstCheckboxElement;
    }    

    if (explainedSpellingCheckBox && explanationSpellingCheckBox) {
      this.createJsPlumbConnection(explainedSpellingCheckBox, explanationSpellingCheckBox);
    }

    // Clear the checkboxes selection
    this.selectedSpellingCheckboxes = [];
    this.spellingLinkingCheckboxTargets.forEach(checkbox => (checkbox.checked = false));
  } 

  increaseConnectionArrowSpacing() {
    this.currentConnectionGaps.horizontalGap += ARROW_CONNECTION_GAP;
    this.currentConnectionGaps.verticalGap += ARROW_CONNECTION_GAP;
  }

  removeExistingConnectionIfAny(sourceId, source) {
    if (this.arrowConnections[sourceId]) {
      const existingConnection = this.jsPlumbInstance.getConnections({ source })[0]; // Get the first (and only) connection
      if (existingConnection) {
        this.removeArrowConnection(existingConnection); // Manually trigger removal
      }
      delete this.arrowConnections[sourceId]; // Remove previous source connection
    }
  }

  createJsPlumbConnection(source, target) {
    this.increaseConnectionArrowSpacing();
    const { horizontalGap, verticalGap } = this.currentConnectionGaps;

    // Ensure jsPlumbInstance is initialized before proceeding
    if (!this.jsPlumbInstance) {
      console.error("jsPlumbInstance is not initialized!");
      return;
    }

    // Validate that both source and target elements are provided
    if (!source || !target) {
      console.error("Invalid source or target:", { source, target });
      return;
    }

    // Get the source and target's unique ids
    const sourceId = source.getAttribute('data-spelling-linkage-checkbox');
    const targetId = target.getAttribute('data-spelling-linkage-checkbox');

    // Since a spelling field cannot be linked to more than one spelling above it, any new connection attempt should replace the previous connection.
    this.removeExistingConnectionIfAny(sourceId, source);

    // Count how many sources are already connected to this target to get the connections count on this target for spacing adjustments between the arrows
    const connectionsCountOnTargetElement = Object.values(this.arrowConnections).filter(id => id === targetId).length;

    // Create the jsPlumb connection between source and target
    // Stub adjustment: If the arrow initially looks like this (->), increasing the stub will extend it to (--->).
    // Offset adjustment: It increases the vertical spacing between arrows, moving them up or down.
    const connection = this.jsPlumbInstance.connect({
      source: source,
      target: target,
      anchors: [
        ["Left", { offset: verticalGap + (connectionsCountOnTargetElement*15) }], // Adjust anchor position based on connection count
        ["Left", { offset: verticalGap + (connectionsCountOnTargetElement*15) }]
      ],
      connector: ["Flowchart", { stub: 20 + horizontalGap, gap: 16, alwaysRespectStubs: true, cornerRadius: 10 }], // Adjust gap to prevent overlap
      paintStyle: { stroke: "#3e5a76", strokeWidth: 5 }, // Arrow styling
      endpoint: "Blank",
      overlays: [
        [
          "Arrow",
          { width: 30, length: 10, location: 1, paintStyle: { fill: "#3e5a76", strokeWidth: 5 }, id: "arrow-overlay" }
        ]
      ]
    });

    // Store the new connection
    this.arrowConnections[sourceId] = targetId;
    this.unhideAllLinkageCheckboxes();
    this.disableDragForArrowConnectedFields(source, target);
    this.setExplanationSpellingTempId(source, target);     
  }

  repaintJsPlumb() {
    jsPlumb.repaintEverything();
  }

  disableCheckBoxInAboveAndBelowFields(event) {
    // Get the checkbox that was clicked
    const checkbox = event.target;

    // Find the closest spelling field container of the clicked checkbox
    const spellingFieldOfClickedCheckBox = checkbox.closest('.js-entries-input'); // Find the closest spelling field
    if (!spellingFieldOfClickedCheckBox) return;

    // Get the hidden field that stores the order number of this spelling field
    const hiddenFieldForOrderNumberOfClickedField = spellingFieldOfClickedCheckBox.querySelector('.js-otto-spelling-assigned-number');
    if (!hiddenFieldForOrderNumberOfClickedField) return;

    // Get the order number of the clicked checkbox's spelling field
    const clickedCheckBoxFieldOrderNumber = parseInt(hiddenFieldForOrderNumberOfClickedField.value, 10);

   // Get all spelling fields on the page
    const spellingsFields = [...document.querySelectorAll('.js-entries-input')];

    let closestLower = null, closestHigher = null;
    let closestLowerDiff = Infinity, closestHigherDiff = Infinity;

    // Loop through all spelling fields to find the closest ones above and below
    spellingsFields.forEach((spellingField) => {
      const hiddenFieldForOrderNumber = spellingField.querySelector('.js-otto-spelling-assigned-number');
      const orderNumberOfSpellingField = parseInt(hiddenFieldForOrderNumber?.value, 10);
      
      // Skip the field that matches the clicked checkbox's order number
      if (orderNumberOfSpellingField === clickedCheckBoxFieldOrderNumber) return;

      const diff = Math.abs(orderNumberOfSpellingField - clickedCheckBoxFieldOrderNumber);

      // Find the closest above field
      if (orderNumberOfSpellingField < clickedCheckBoxFieldOrderNumber && diff < closestLowerDiff) {
        closestLower = spellingField;
        closestLowerDiff = diff;
      }

      // Find the closest below field
      if (orderNumberOfSpellingField > clickedCheckBoxFieldOrderNumber && diff < closestHigherDiff) {
        closestHigher = spellingField;
        closestHigherDiff = diff;
      }
    });

    // If the checkbox is checked, hide checkboxes in the closest above and below fields
    if (checkbox.checked) {
      if (closestLower) {
        const lowerCheckbox = closestLower.querySelector('.js-spelling-linkage-checkbox');
        if (lowerCheckbox) lowerCheckbox.classList.add('d-none');
      }
      if (closestHigher) {
        const higherCheckbox = closestHigher.querySelector('.js-spelling-linkage-checkbox');
        if (higherCheckbox) higherCheckbox.classList.add('d-none');
      }
    } else {
      // If the checkbox is unchecked, unhide the checkboxes in the closest above and below fields
      if (closestLower) {
        const lowerCheckbox = closestLower.querySelector('.js-spelling-linkage-checkbox');
        if (lowerCheckbox) lowerCheckbox.classList.remove('d-none');
      }
      if (closestHigher) {
        const higherCheckbox = closestHigher.querySelector('.js-spelling-linkage-checkbox');
        if (higherCheckbox) higherCheckbox.classList.remove('d-none');
      }
    }
  }

  unhideAllLinkageCheckboxes() {
    document.querySelectorAll('.js-spelling-linkage-checkbox').forEach((checkbox) => {
      checkbox.classList.remove('d-none');
    });
  }

  // ************ Code to make linkages between spellings through arrows ENDS here ************ //

}